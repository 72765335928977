@import url("https://cdnjs.cloudflare.com/ajax/libs/select2/4.0.3/css/select2.min.css");
@import url("https://fonts.googleapis.com/css2?family=Mulish:wght@200;300;400;500;600;700;800;900;1000&display=swap");

html {
  font-size: 10px;
  scroll-behavior: smooth;
}

*,
body {
  box-sizing: border-box;
  font-family: "Mulish", sans-serif;
}

body {
  margin: 0;
  padding: 0;
  overflow-x: hidden;
}

body.active,
html.active {
  overflow: hidden;
}

body.active2,
html.active2 {
  overflow: hidden;
  width: 100vw;
  height: 100vh;
}

section {
  position: relative;
}

input:-webkit-autofill,
input:-webkit-autofill:focus {
  transition: background-color 600000s 0s, color 600000s 0s;
}

.container {
  padding: 0 1.6rem;
  max-width: 136rem;
  margin: 0 auto;
}

@media screen and (max-width: 1199.9px) {
  html {
    font-size: 9px;
  }
}

@media screen and (max-width: 1023.9px) {
  html {
    font-size: 8px;
  }
}

@media screen and (max-width: 991.9px) {
  html {
    font-size: 7px;
  }
}

.row {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-gap: 0 1.6rem;
}

@media screen and (min-width: 1199.9px) {
  .xl-1 {
    grid-column: span 1;
  }

  .xl-2 {
    grid-column: span 2;
  }

  .xl-3 {
    grid-column: span 3;
  }

  .xl-4 {
    grid-column: span 4;
  }

  .xl-5 {
    grid-column: span 5;
  }

  .xl-6 {
    grid-column: span 6;
  }

  .xl-7 {
    grid-column: span 7;
  }

  .xl-8 {
    grid-column: span 8;
  }

  .xl-9 {
    grid-column: span 9;
  }

  .xl-10 {
    grid-column: span 10;
  }

  .xl-12 {
    grid-column: span 12;
  }
}

@media screen and (max-width: 1199.9px) and (min-width: 991.9px) {
  .lg-0 {
    display: none;
  }

  .lg-1 {
    grid-column: span 1;
  }

  .lg-2 {
    grid-column: span 2;
  }

  .lg-3 {
    grid-column: span 3;
  }

  .lg-4 {
    grid-column: span 4;
  }

  .lg-5 {
    grid-column: span 5;
  }

  .lg-6 {
    grid-column: span 6;
  }

  .lg-7 {
    grid-column: span 7;
  }

  .lg-8 {
    grid-column: span 8;
  }

  .lg-9 {
    grid-column: span 9;
  }

  .lg-10 {
    grid-column: span 10;
  }

  .lg-12 {
    grid-column: span 12;
  }
}

@media screen and (max-width: 991.9px) and (min-width: 767.9px) {
  .md-0 {
    display: none;
  }

  .md-1 {
    grid-column: span 1;
  }

  .md-2 {
    grid-column: span 2;
  }

  .md-3 {
    grid-column: span 3;
  }

  .md-4 {
    grid-column: span 4;
  }

  .md-5 {
    grid-column: span 5;
  }

  .md-6 {
    grid-column: span 6;
  }

  .md-7 {
    grid-column: span 7;
  }

  .md-8 {
    grid-column: span 8;
  }

  .md-9 {
    grid-column: span 9;
  }

  .md-10 {
    grid-column: span 10;
  }

  .md-12 {
    grid-column: span 12;
  }
}

@media screen and (max-width: 767.9px) {
  .sm-0 {
    display: none;
  }

  .row {
    grid-gap: 0 0.8rem;
  }

  .sm-3 {
    grid-column: span 3;
  }

  .sm-6 {
    grid-column: span 6;
  }

  .sm-12 {
    grid-column: span 12;
  }

  html {
    font-size: 10px;
  }

  .container {
    max-width: 100%;
    padding-left: 1.6rem;
    padding-right: 1.6rem;
  }
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  appearance: none;
  margin: 0;
}

input::placeholder,
textarea::placeholder,
select::placeholder {
  color: #121212;
}

input:focus::placeholder,
textarea:focus::placeholder {
  color: transparent !important;
}

p {
  margin: 0;
}

a {
  text-decoration: none;
  display: block;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}

button {
  outline: 0;
  cursor: pointer;
}

button,
input,
textarea,
select {
  outline: 0;
}

ul {
  padding: 0;
  list-style-type: none;
  margin: 0;
}

body {
  background-color: #ffffff;
}

header > div:first-child {
  border-bottom: 1px solid #e6e8ed;
}

header .container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
}
.menuBtn {
  display: none;
}
.headerMid {
  display: flex;
  gap: 5.6rem;
}
.headerMenu {
  display: flex;
  align-items: center;
  gap: 3rem;
}

.headerMenu li a {
  font-style: normal;
  font-weight: 500;
  font-size: 1.4rem;
  line-height: 1.8rem;
  letter-spacing: -0.03em;
  color: #4d4d4d;
}

.headerMenu li a:hover,
.lang a:hover {
  color: #0d8359;
  opacity: 1;
}

.headerRight {
  display: flex;
  align-items: center;
  height: 100%;
}
.phoneNumber {
  font-style: normal;
  font-weight: 800;
  font-size: 2.1rem;
  line-height: 2.6rem;
  color: #182322;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.3s;
}
.phoneNumber svg {
  margin-right: 0.4rem;
}
.phoneNumber path {
  transition: all 0.3s;
}
.phoneNumber:hover {
  color: #0d8359;
}
.phoneNumber:hover path {
  fill: #0d8359;
}

.callOrder {
  font-style: normal;
  font-weight: 700;
  font-size: 1.5rem;
  line-height: 1.9rem;
  color: #ffffff;
  background-color: #ff2f49;
  border-radius: 5rem;
  padding: 0.9rem 2.1rem;
  transition: all 0.3s;
  border: none;
}
.callOrder:hover {
  background-color: #d6001b;
}
.callOrder:active {
  background-color: #9e0014;
}
.callOrder:disabled {
  opacity: 0.3;
}
.headerRight > div {
  border-right: 0.08rem solid #f2f0f0;
  height: 7rem;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 2.4rem;
}
.whatsapp {
  opacity: 0.7;
  transition: all 0.3s;
}
.whatsapp:hover {
  opacity: 1;
}
.headerRight > div:first-child {
  border-left: 0.1rem solid #f2f0f0;
}
.headerAddress {
  flex-direction: column;
  row-gap: 1rem;
}
.headerAddress span {
  display: flex;
  align-items: center;
  column-gap: 0.8rem;
  width: 100%;
  font-style: normal;
  font-weight: 400;
  font-size: 1.2rem;
  line-height: 1.5rem;
  letter-spacing: -0.03em;
  color: #000000;
}
.css-10wo9uf-option,
.css-d7l1ni-option {
  font-size: 1.6rem !important;
}
.lang {
  gap: 0.8rem;
}
.lang a {
  font-style: normal;
  font-weight: 400;
  font-size: 1.4rem;
  line-height: 1.8rem;
  color: #000000;
  opacity: 0.6;
  transition: all 0.3s;
  cursor: pointer;
}
.lang a:hover {
  color: #0d8359;
  opacity: 1;
}
.headerSocials {
  gap: 1.8rem;
  justify-content: flex-end !important;
  padding-right: 0 !important;
  border-right: none !important;
}
.headerSocials path {
  transition: all 0.3s;
}
.headerSocials a:hover path {
  fill: #0d8359;
}
.searchBtn {
  display: none;
}
header > div:nth-child(2) {
  padding: 3.3rem 0 4.4rem;
}
.categories {
  display: flex;
  align-items: center;
  gap: 4.5rem;
  padding-left: 5.6rem;
}
.categoryMenu {
  display: flex;
  align-items: center;
  gap: 3rem;
  margin-top: 1.9rem;
}

.categoryMenu > li > a,
.campaignsLink {
  font-style: normal;
  font-weight: 500;
  font-size: 1.8rem;
  line-height: 2.3rem;
  letter-spacing: -0.02em;
  color: #202e2d;
  transition: all 0.3s;
  display: inline-block;
  position: relative;
  padding-bottom: 1.9rem;
  border-bottom: 0.3rem solid transparent;
}
.categoryMenu > li:hover > a {
  color: #ed213a;
  border-color: #ed213a;
}
.campaignsLink {
  font-weight: 800;
  color: #202e2d;
  border-color: transparent;
}
.campaignsLink span {
  color: #ff2f49;
  font-weight: 800;
}
.categoryMenu > li > a:hover,
.campaignsLink:hover {
  color: #ed213a;
  border-color: #ed213a;
}

.search {
  transition: all 0.5s;
  position: absolute;
  right: 18rem;
}
.search .inputGroup {
  display: flex;
}
.search input {
  height: 5.4rem;
  background-color: #f4f7f7;
  border: none;
  border-top-left-radius: 7rem;
  border-bottom-left-radius: 7rem;
  padding: 0 3.2rem;
  width: 33rem;
  font-style: normal;
  font-weight: 500;
  font-size: 1.6rem;
  line-height: 2rem;
  letter-spacing: -0.02em;
  color: #000000;
  transition: all 0.5s;
}
.search input::placeholder {
  color: #566160;
}
.search input.active {
  width: 92rem;
}
.search button {
  width: 9rem;
  height: 5.4rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-top-right-radius: 7rem;
  border-bottom-right-radius: 7rem;
  border: none;
  background-color: #f4f7f7;
}
.headerBottomRight {
  display: flex;
  justify-content: flex-end;
  gap: 2.7rem;
  align-items: center;
  margin-left: auto;
}
.headerBottomRight a path {
  transition: all 0.3s;
}
.headerBottomRight a:hover path {
  fill: #0d8359;
}
.banner .container {
  position: relative;
}
.bannerSwiper .swiper-slide a {
  height: 40rem;
}
.bannerSwiper .swiper-slide a img {
  width: 100%;
}
.swiper-button-next,
.swiper-button-prev,
.swiper-pagination {
  z-index: 3;
}
.bannerSwiper .swiper-button-next:after {
  content: url("./assets/icon/bannerright.svg");
}
.bannerSwiper .swiper-button-prev:after {
  content: url("./assets/icon/bannerleft.svg");
}
.bannerSwiper {
  position: unset !important;
}
.bannerSwiper .swiper-button-next,
.bannerSwiper .swiper-button-prev {
  width: 6.2rem;
  height: 6.2rem;
  border-radius: 50%;
  background-color: #fff;
  z-index: 5;
}
.bannerSwiper .swiper-button-next {
  right: -1.2rem;
}
.bannerSwiper .swiper-button-prev {
  left: -1.2rem;
}
.bannerSwiper .swiper-horizontal > .swiper-pagination-bullets,
.bannerSwiper .swiper-pagination-bullets.swiper-pagination-horizontal {
  width: auto;
  background-color: #fff;
  padding: 2rem 3rem;
  border-radius: 10rem;
  left: 50%;
  transform: translateX(-50%);
  bottom: -2.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 3.6rem;
  z-index: 4;
}
.bannerSwiper .swiper-pagination-bullet {
  width: 1.1rem;
  height: 1.1rem;
  background-color: #d3dddc;
  opacity: 1;
  margin: 0 !important;
  transition: all 0.3s;
}
.bannerSwiper .swiper-pagination-bullet-active {
  background-color: #91a8a7;
}
.bannerSwiper .swiper-pagination-bullet-active:hover {
  background-color: #91a8a7 !important;
}
.bannerSwiper .swiper-pagination-bullet:hover {
  background-color: #0d8359;
}
.bannerSwiper .swiper-pagination-bullet:active {
  background-color: #0a5a3d;
}
.detailSearch {
  padding: 8rem 0;
}
.detailSearch input,
.dimensionFilter input {
  font-size: 1.8rem !important;
  width: 10rem !important;
  grid-area: unset !important;
}
.dimensionFilter input {
  padding-left: 0 !important;
}
.detailSearch h2 {
  font-style: normal;
  font-weight: 800;
  font-size: 3.9rem;
  line-height: 4.9rem;
  letter-spacing: -0.02em;
  color: #2f3131;
  margin-bottom: 2.7rem;
}
.detailSearchContent {
  padding: 3.3rem 3rem 7.9rem;
  box-shadow: 0px 7px 24px rgba(0, 0, 0, 0.1);
  border-radius: 1.4rem;
  position: relative;
}
.detailSearchContent .react-tabs__tab-list {
  border-bottom: 1px solid #dee0e0;
  display: flex;
  column-gap: 6rem;
  padding-bottom: 2.05rem;
}
.detailSearchContent .react-tabs__tab {
  font-style: normal;
  font-weight: 400;
  font-size: 2.5rem;
  line-height: 3.1rem;
  letter-spacing: -0.02em;
  color: #91a8a7;
  position: relative;
  border-top: none;
  border-left: none;
  border-right: none;
  border-radius: 0;
}

.detailSearchContent .react-tabs__tab:focus::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 4px;
  background-color: #ff2f49;
  bottom: -2.3rem;
  left: 0;
}

.detailSearchContent .react-tabs__tab--selected::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 4px;
  background-color: #ff2f49;
  bottom: -2.3rem;
  left: 0;
}

.detailSearchContent .react-tabs__tab--selected {
  color: #202e2d;
  font-weight: 700;
}
.detailSearchContent .checkboxGroup {
  display: flex;
  align-items: center;
  column-gap: 5rem;
  padding: 3.2rem 0 4.3rem;
}
.detailSearchContent .checkboxGroup div {
  display: flex;
  align-items: center;
  column-gap: 0.9rem;
}
.detailSearchContent .checkboxGroup label {
  font-style: normal;
  font-weight: 500;
  font-size: 1.6rem;
  line-height: 2rem;
  letter-spacing: -0.02em;
  color: #91a8a7;
  transition: all 0.3s;
}
.detailSearchContent .checkboxGroup label:hover {
  color: #0d8359;
}
.detailSearchContent .checkboxGroup input:checked + label {
  color: #2d2d2d;
}
.selection {
  width: 23.4rem;
}
.detailSearchContent .inputGroup {
  display: flex;
  align-items: center;
  column-gap: 2.1rem;
}
.form-group label {
  font-weight: 500;
  font-size: 1.6rem;
  line-height: 2rem;
  letter-spacing: -0.02em;
  color: #6e7e7d;
  display: block;
  margin-bottom: 0.6rem;
  padding-left: 0.8rem;
}
.detailSearchContent .inputGroup select {
  width: 23.4rem;
  border: none;
}
.select2-container--default .select2-selection--single {
  border: none;
  background-color: #f4f7f7;
  height: 6rem;
  width: 23.4rem;
  border-radius: 5px !important;
}
.detailSearch .tabs-container {
  padding-top: 1rem;
}
.detailSearch .tabs-container .inputGroup {
  margin-bottom: 2rem;
  width: 60%;
  flex-wrap: wrap;
  row-gap: 2rem;
}
.select2-container .select2-selection--single .select2-selection__rendered {
  height: 6rem;
  display: flex;
  align-items: center;
  padding-left: 2.5rem;
  font-style: normal;
  font-weight: 500;
  font-size: 1.6rem;
  line-height: 2rem;
  letter-spacing: -0.02em;
  color: #91a8a7;
}
.select2-container--default
  .select2-selection--single
  .select2-selection__arrow {
  top: 50%;
  right: 1.8rem;
  transform: translateY(-50%);
}
.select2-container--default
  .select2-selection--single
  .select2-selection__arrow
  b {
  border-width: 7px 5px 0 5px;
  border-color: #b6c4c4 transparent transparent transparent;
  transition: all 0.3s;
}
.select2-container--default.select2-container--open
  .select2-selection--single
  .select2-selection__arrow
  b {
  border-width: 0 5px 7px 5px;
  border-color: transparent transparent #0d8359 transparent;
}
.detailSearchContent .tabs-container button {
  width: 17.7rem;
  height: 6.3rem;
  background-color: #ff2f49;
  border: none;
  border-radius: 10rem;
  font-style: normal;
  font-weight: 700;
  font-size: 1.7rem;
  line-height: 2.1rem;
  letter-spacing: -0.02em;
  color: #ffffff;
  position: absolute;
  bottom: -3.1rem;
  transition: all 0.4s;
}
.detailSearchContent .tabs-container button:hover {
  background-color: #d6001b;
}
.detailSearchContent .tabs-container button:active {
  background-color: #9e0014;
}
.detailSearchContent .tabs-container .imgContainer {
  width: 25rem;
  height: 15rem;
  overflow: hidden;
  position: absolute;
  bottom: 0;
  right: 2rem;
  transition: all 0.4s ease-in-out;
}
.detailSearchContent .tabs-container img {
  width: 100% !important;
}
.detailSearchContent .tabs-container .filter-image {
  width: 30px !important;
}

.productCategories {
  padding: 7.1rem 0 8.9rem;
  background-color: #f4f7f7;
}
.productCategories h1,
.products h1 {
  font-style: normal;
  font-weight: 800;
  font-size: 3.9rem;
  line-height: 4.9rem;
  color: #000000;
  margin-bottom: 9.3rem;
}
.productsSWiper .swiper-wrapper {
  padding-bottom: 0.1rem;
}
.productCategoriesContent {
  display: flex;
  row-gap: 12.7rem;
  flex-wrap: wrap;
}
.productCategoriesContent a {
  width: calc(100% / 3);
  position: relative;
  padding: 0 1.1rem;
}
.productCategoriesContent a:nth-child(8) img {
  width: 22rem;
  height: auto !important;
  margin-top: 3rem;
}
.productCategoriesContent a:nth-child(10) img {
  width: 15rem;
  height: auto !important;
  margin-top: -3rem;
}
.productCategoriesContent a:nth-child(11) img {
  width: 11rem;
  height: auto !important;
  margin-top: -2rem;
}
.productCategoriesContent a span {
  font-style: normal;
  font-weight: 400;
  font-size: 2.2rem;
  line-height: 2.8rem;
  letter-spacing: -0.03em;
  color: #182322;
  width: 100%;
  display: block;
  background: #ffffff;
  border-radius: 16px;
  padding: 6.6rem 6rem 1.8rem 3.5rem;
  height: 14rem;
}
.productCategoriesContent a img {
  position: absolute;
  top: -6rem;
  left: 3rem;
  transition: all 1s;
  height: 9rem;
}
.productCategoriesContent a:nth-child(5) img,
.productCategoriesContent a:nth-child(6) img,
.productCategoriesContent a:nth-child(7) img,
.productCategoriesContent a:nth-child(8) img,
.productCategoriesContent a:nth-child(9) img {
  height: 10rem;
}
.productCategories a:hover img {
  transform: translateX(5rem);
}
.products {
  padding: 5rem 0;
}
.products h1 {
  margin-bottom: 0;
}
.productsTop {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 3.2rem;
}
.seeAll {
  display: flex;
  column-gap: 1.5rem;
  align-items: center;
  padding: 0;
  background-color: transparent;
  border: none;
  padding: 1rem 3rem;
  border: 1px solid rgba(255, 47, 74, 0.5);
  border-radius: 0.4rem;
  background-color: rgba(255, 47, 74, 1);
}
.seeAll span {
  font-style: normal;
  font-weight: 500;
  font-size: 2rem;
  line-height: 2rem;
  letter-spacing: -0.03em;
  color: #ffffff;
}
.seeAll path {
  fill: #ffffff;
}
.seeAll:hover svg {
  animation: seeAll 0.5s linear infinite;
}

@keyframes seeAll {
  0% {
    transform: translateX(0);
  }
  50% {
    transform: translateX(0.5rem);
  }
  100% {
    transform: translateX(0);
  }
}
.popup {
  background: #fafafa;
  border: 1.1086px solid #f2f2f2;
  border-radius: 11.086px;
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 1.774rem;
  padding: 1.47rem 5.2rem;
  position: fixed;
  bottom: 3.6rem;
  left: 50%;
  transform: translate(-50%, 3rem);
  opacity: 0;
  visibility: hidden;
  z-index: 15;
  font-weight: 600;
  font-size: 1.6rem;
  line-height: 2rem;
  letter-spacing: -0.02em;
  color: #202e2d;
  transition: all 0.2s linear;
}
.popup.active {
  visibility: visible;
  opacity: 1;
  transform: translate(-50%, 0);
}
.empty-products {
  display: flex;
  justify-content: center;
  width: 78%;
  position: absolute;
  right: 0;
}
.empty-products p {
  width: auto !important;
  padding: 2rem 6rem;
  border-radius: 1rem;
  font-size: 2.4rem !important;
  background-color: #ff2f49;
  color: #ffffff !important;
}
.product {
  border: 1px solid #ece9e9;
  position: relative;
  background-color: #fff;
  transition: all 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94);
}
.productImg {
  border-bottom: 1px solid #ece9e9;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  position: relative;
  transition: all 0.5s ease-in-out;
  height: 32rem;
}
.productImg img {
  transition: all 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94) !important;
}
.product:hover .productBody {
  background-color: #f4f7f7;
}
.product:hover .productImg img {
  scale: 0.95;
}
.productBody {
  padding: 2.75rem 3.05rem 2.1rem 2.45rem;
  min-height: 29.1rem;
  display: flex;
  flex-direction: column;
  transition: all 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94);
}
.productDetails {
  display: flex;
  justify-content: space-between;
  margin-bottom: 0.8rem;
}
.productDetails > div:first-child {
  display: flex;
  flex-direction: column;
  row-gap: 0.5rem;
  min-height: 6.9rem;
}
.inStock {
  padding-bottom: 0.65rem;
  display: flex;
  column-gap: 0.4rem;
}
.inStock span {
  text-transform: lowercase;
  padding: 0.2rem 0.8rem 0.3rem;
  border-radius: 1.25rem;
  font-size: 1.3rem;
  font-weight: 700;
  line-height: normal;
  letter-spacing: -0.022rem;
}
.stockTrue {
  background-color: rgba(13, 131, 89, 1);
  color: #ffffff;
}
.stockTrueCount {
  background-color: rgba(236, 223, 110, 1);
  color: rgba(14, 64, 46, 1);
}
.stockFalse {
  background-color: rgba(251, 55, 67, 1);
  color: #ffffff;
}
.tireBrand {
  font-style: normal;
  font-weight: 400;
  font-size: 1.4rem;
  line-height: 1.8rem;
  letter-spacing: -0.03em;
  color: #000000;
  text-transform: uppercase;
  display: flex;
  column-gap: 1.75rem;
  align-items: center;
}
.country {
  display: flex;
  align-items: center;
  column-gap: 0.4rem;
  position: relative;
}
.country::before {
  content: "";
  position: absolute;
  right: calc(100% + 1.7rem / 2);
  width: 0.3rem;
  height: 0.3rem;
  background-color: rgba(206, 217, 213, 1);
  border-radius: 50%;
}
.brand .country::before {
  display: none;
}
.brand .country span {
  font-size: 1.4rem;
  color: #010302;
}
.brand .country {
  column-gap: 1rem;
}
.country div {
  min-width: 1.4rem;
  height: 1.4rem;
  border-radius: 50%;
  overflow: hidden;
}
.country div img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.country span {
  font-size: 1.1rem;
  font-weight: 600;
  line-height: 1.4rem;
  letter-spacing: -0.02em;
  text-transform: capitalize;
}
.tireName {
  font-style: normal;
  font-weight: 700;
  font-size: 1.8rem;
  line-height: 2.3rem;
  letter-spacing: -0.02em;
  color: #000000;
  transition: all 0.3s;
  height: 6.9rem;
}
.product:hover .tireName {
  color: #0d8359;
}
.tireTypes {
  display: flex;
  flex-direction: column;
  row-gap: 0.9rem;
  align-items: center;
}
.productPrice {
  display: flex;
  column-gap: 2.2rem;
  align-items: center;
  margin-bottom: 0.5rem;
}
.productPrice > div:first-child {
  display: flex;
}
.tirePrice {
  font-style: normal;
  font-weight: 700;
  font-size: 1.8rem;
  line-height: 2.3rem;
  letter-spacing: -0.02em;
  color: #ffffff;
  background: #eb001e;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 0.4rem;
  padding: 1.1rem 2.2rem;
  height: 4.4rem;
  position: relative;
}
.tirePrice::after {
  content: "";
  position: absolute;
  height: 2.3rem;
  width: 0.1rem;
  background-color: #ffacb6;
  right: 0;
}
.addToBasket {
  height: 4.4rem;
  padding: 0 2.2rem;
  background: #eb001e;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.3s;
}
.addToBasket:hover {
  background-color: #d6001b;
}
.addToBasket:active {
  background-color: #9e0014;
}
.addToFav {
  border: none;
  background-color: transparent;
}
.addToFav svg {
  transition: all 0.3s;
}
.addToFav path {
  transition: all 0.3s;
}
.addToFav:hover path {
  stroke: #ed213a;
}
.addToFav:active path {
  fill: #ed213a;
}
.oldPrice span {
  font-style: normal;
  font-weight: 700;
  font-size: 1.8rem;
  line-height: 2.3rem;
  letter-spacing: -0.02em;
  color: #91a8a7;
  display: inline-flex;
  align-items: center;
  column-gap: 0.4rem;
  position: relative;
  margin-bottom: 1.65rem;
}
.oldPrice span::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 1px;
  background-color: #ff2f49;
}
.tireBrandImg {
  display: flex;
  justify-content: center;
  height: 3.4rem;
  margin-top: auto;
}
.tireBrandImg img {
  width: 10rem;
  height: unset !important;
  object-fit: contain;
}
.prodBadgeLeft,
.prodBadgeRight {
  position: absolute;
  top: 2.4rem;
  display: flex;
  flex-direction: column;
  row-gap: 0.8rem;
}
.prodBadgeLeft {
  left: 2.4rem;
}
.prodBadgeRight {
  right: 1.8rem;
}
.newProduct,
.discount,
.freeMounting,
.taksit {
  border-radius: 3rem;
  font-family: "Mulish";
  font-style: normal;
  font-weight: 700;
  font-size: 1.2rem;
  line-height: 100%;
  text-align: center;
  letter-spacing: -0.02em;
}
.newProduct {
  padding: 0.6rem 1.4rem;
}
.discount {
  padding: 0.8rem 1.8rem;
}
.newProduct {
  background-color: #4ebe96;
  color: #fff;
}
.discount {
  background-color: #d6001b;
  color: #ffffff;
  font-size: 1.4rem;
}
.freeMounting,
.taksit {
  padding: 0.6rem 0.8rem;
}
.freeMounting {
  background-color: #ff2f49;
  color: #fff;
}
.taksit {
  background-color: #2ca0c5;
  color: #fff;
}
.newProducts {
  background-color: #f4f7f7;
}
.brandSwiper {
  padding-top: 5.1rem;
}
.brandImg {
  height: 8rem;
  width: 16rem;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: visible;
}
.brandImg img {
  max-width: 100%;
  max-height: 100%;
  transition: all 0.3s;
  object-fit: cover;
  width: 85%;
  height: 90%;
  overflow: visible;
}
.brand {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  row-gap: 2.8rem;
}
.brandName {
  font-style: normal;
  font-weight: 500;
  font-size: 1.6rem;
  line-height: 170%;
  text-align: center;
  letter-spacing: -0.02em;
  color: #000000;
  transition: all 0.3s;
  position: relative;
  z-index: 2;
}
.brand:hover img {
  transform: scale(1.1);
}
.brand:hover .brandName {
  color: #ed213a;
}
.services {
  padding: 6rem 0;
  background-color: #f7f8f8;
}
.services h1 {
  font-style: normal;
  font-weight: 800;
  font-size: 3.9rem;
  line-height: 4.9rem;
  letter-spacing: 0.01em;
  color: #000000;
  margin-bottom: 8rem;
}
.servicesContent {
  display: flex;
}
.service {
  height: 15rem;
  border-right: 1px solid #ece6e6;
  width: calc(100% / 3);
  display: flex;
  flex-direction: column;
  row-gap: 1.4rem;
  padding: 0 7.6rem;
}
.service:last-child {
  border: none;
}
.serviceName {
  display: flex;
  align-items: center;
  column-gap: 1.7rem;
}
.serviceName span {
  font-style: normal;
  font-weight: 700;
  font-size: 2rem;
  line-height: 2.5rem;
  letter-spacing: -0.01em;
  color: #202e2d;
  width: 40%;
}
.service p {
  font-style: normal;
  font-weight: 400;
  font-size: 1.4rem;
  line-height: 170%;
  letter-spacing: -0.02em;
  color: #000000;
}
.videoContainer {
  height: 23.8rem;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  margin-bottom: 2rem;
  position: relative;
}
.videoContainer img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: all 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94);
}
.video:hover img {
  scale: 1.1;
}
.videoContainer iframe {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.video p {
  font-style: normal;
  font-weight: 700;
  font-size: 2rem;
  line-height: 2.5rem;
  color: #202e2d;
  width: 80%;
}
.video:hover p {
  color: #0a5a3d;
}
.videoContainer button {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  height: 6.2rem;
  width: 6.2rem;
  background: rgba(255, 47, 73, 0.6);
  border: none;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.blogImg {
  height: 40rem;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  margin-bottom: 2rem;
  position: relative;
}
.blogImg img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: all 0.5s;
}
.blog p {
  font-style: normal;
  font-weight: 700;
  font-size: 2rem;
  line-height: 2.5rem;
  color: #202e2d;
  width: 70%;
}
.blog:hover img {
  transform: scale(1.1);
}

.blog:hover p {
  color: #0a5a3d;
}
.blogBg {
  position: absolute;
  width: 100%;
  height: 100%;
  background: #7e7979;
  mix-blend-mode: multiply;
  opacity: 0;
  transition: all 0.4s;
}
.blogHover {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  row-gap: 2rem;
  flex-direction: column;
  opacity: 0;
  transition: all 0.4s;
}
.blogHover span {
  font-style: normal;
  font-weight: 600;
  font-size: 2.27rem;
  line-height: 2.9rem;
  letter-spacing: -0.03em;
  color: #ffffff;
}
.blog:hover .blogBg,
.blog:hover .blogHover {
  opacity: 1;
}
.bottomText {
  padding: 2rem 0 5rem;
}
.bottomText h3 {
  font-style: normal;
  font-weight: 700;
  font-size: 2rem;
  line-height: 3rem;
  color: #000000;
  width: 60%;
  margin-bottom: 1.2rem;
}
.bottomText p {
  font-style: normal;
  font-weight: 400;
  font-size: 1.4rem;
  line-height: 2.5rem;
  color: #000000;
  opacity: 0.8;
  margin-bottom: 1.4rem;
  transition: all 0.3s;
}
.bottomText a {
  display: inline-block;
  color: #d6001b;
  font-weight: 500;
  margin-right: 0.4rem;
}
.bottomText ul {
  display: flex;
  flex-direction: column;
  row-gap: 1.4rem;
  list-style: disc;
  padding-left: 4rem;
  margin-bottom: 2rem;
}
.seoText {
  position: relative;
  height: 11rem;
  overflow: hidden;
  margin-bottom: 2rem;
}
.seoText.active {
  overflow: visible;
  height: auto;
}
.bottomText .seeAll span:last-child {
  display: none;
}
.bottomText .seeAll.active span:last-child {
  display: inline;
}
.bottomText .seeAll.active span:first-child {
  display: none;
}
footer {
  background-color: #202e2d;
  padding: 7.5rem 0 7.2rem;
}
.footerContact {
  display: flex;
  padding-bottom: 2rem;
  border-bottom: 1px solid #425756;
}
.footerPhone a {
  display: flex;
  align-items: center;
  column-gap: 1.1rem;
}
.footerPhone path {
  transition: all 0.3s;
}
.footerPhone a:hover span {
  color: #0d8359;
}
.footerPhone a:hover path {
  fill: #0d8359;
}
.footerPhone a span {
  font-style: normal;
  font-weight: 800;
  font-size: 3rem;
  line-height: 3.8rem;
  color: #8b9d9c;
  display: flex;
  transition: all 0.3s;
}
footer .callOrder {
  background-color: #465856;
  display: inline-block;
}
footer .headerAddress span {
  color: #fff;
}
footer .headerAddress path {
  fill: #465856;
}
.footerContact > div {
  width: calc(75% / 4);
  border-right: 1px solid rgba(66, 87, 86, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
}
.footerSocials {
  width: 25% !important;
  display: flex;
  column-gap: 3rem;
  align-items: center;
  justify-content: center;
}
.footerContact > div:last-child {
  border: none;
}
.footerContact > div:nth-child(4) {
  padding-left: 4.2rem;
}
.footerSocials path {
  transition: all 0.3s;
}
.footerSocials a:hover path {
  fill: #0d8359;
}
.footerContent {
  padding: 5.7rem 0 5.1rem 2rem;
  border-bottom: 1px solid #4d4d4d;
  display: flex;
}
.footerLeft > ul > a {
  font-style: normal;
  font-weight: 600;
  font-size: 2.2rem;
  line-height: 2.8rem;
  color: #ffffff;
  margin-bottom: 1.8rem;
  transition: all 0.3s;
  display: inline-block;
}
.footerLeft {
  width: 80%;
  display: flex;
}
.footerLeft > ul {
  display: flex;
  flex-direction: column;
  row-gap: 1.3rem;
  width: 25%;
}
.footerLeft ul li a {
  font-style: normal;
  font-weight: 500;
  font-size: 1.3rem;
  line-height: 1.8rem;
  letter-spacing: -0.02em;
  color: #91a8a7;
  transition: all 0.3s;
  display: inline-block;
  width: 95%;
}
.footerLeft ul li a:hover,
.footerMid ul li a:hover {
  color: #fff;
}
.footerLeft > div {
  display: flex;
  justify-content: space-between;
}
.footerRight {
  width: 20%;
}
.footerMenu,
.footerMenu > div {
  display: flex;
  flex-direction: column;
  row-gap: 1.2rem;
}
.footerMenu li a {
  font-style: normal;
  font-weight: 600;
  font-size: 2.2rem;
  line-height: 2.8rem;
  color: #ffffff;
  display: inline-block;
  transition: all 0.3s;
}
.footerContent > div > a:hover,
.footerMenu li a:hover {
  color: #13996a;
}
.copyright {
  padding-top: 1.7rem;
}

.copyright > .container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.copyright p {
  font-style: normal;
  font-weight: 500;
  font-size: 1.24rem;
  line-height: 1.7rem;
  letter-spacing: 0.0099em;
  color: #ffffff;
  opacity: 0.46;
}

.copyright > .container > div {
  display: flex;
  align-items: center;
  gap: 1.7rem;
}

.copyright > .container > div span {
  font-style: normal;
  font-weight: 400;
  font-size: 1.4rem;
  line-height: 1.8rem;
  text-align: center;
  color: #91a8a7;
}

.copyright > .container > div a {
  font-style: normal;
  font-weight: 500;
  font-size: 1.4rem;
  line-height: 1.9rem;
  letter-spacing: 0.0099em;
  color: #ffffff;
  position: relative;
  transition: all 0.4s ease-in-out;
}

.copyright > .container > div a::before {
  left: 50%;
}

.copyright > .container > div a::after {
  right: 50%;
}

.copyright > .container > div a::before,
.copyright > .container > div a::after {
  content: "";
  position: absolute;
  bottom: -0.5rem;
  height: 0.2rem;
  width: 0%;
  background-color: #e81e75;
  transition: all 0.4s ease-in-out;
}

.copyright > .container > div a:hover {
  color: #e81e75;
}

.copyright > .container > div a:hover::before,
.copyright > .container > div a:hover::after {
  width: 50%;
}
.searchResults {
  width: 100vw;
  height: 80vh;
  padding: 4.1rem 0 7.5rem;
  background-color: #ffffff;
  position: fixed;
  left: 0;
  z-index: 10;
  overflow: auto;
  visibility: hidden;
  opacity: 0;
  transition: opacity 0.3s ease;
}
.searchResults.active {
  opacity: 1;
  visibility: visible;
}
.searchResult {
  display: flex;
  align-items: center;
  width: 100%;
}
.searchResult:hover .searchResultName {
  color: #0d8359;
}
.searchResult:hover .imgContainer {
  transform: translateX(-1rem);
}
.searchResult:hover .searchPrice {
  transform: translateX(1rem);
}
.searchResult .imgContainer {
  width: 12.2rem;
  height: 12.2rem;
  border: 1px solid #e5e6f1;
  border-radius: 11.2px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.3s;
}
.searchResult .imgContainer img {
  height: 95%;
}
.searchResultName {
  font-style: normal;
  font-weight: 600;
  font-size: 2.4rem;
  line-height: 3rem;
  letter-spacing: -0.01em;
  color: #0e192d;
  padding-left: 4.2rem;
  width: 60%;
  transition: all 0.3s;
}
.searchResults .container {
  padding: 0;
  width: 101rem;
  margin: 0 auto;
  display: block;
}
.searchPrice {
  display: flex;
  align-items: center;
  column-gap: 3.2rem;
  margin-left: auto;
  transition: all 0.3s;
}
.searchOldPrice {
  font-style: normal;
  font-weight: 400;
  font-size: 2.1rem;
  line-height: 2.7rem;
  letter-spacing: -0.07em;
  color: #707070;
  position: relative;
}
.searchOldPrice::after {
  content: "";
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  height: 0.13rem;
  background-color: #232323;
  transform: rotate(10deg);
}
.searchCurrentPrice {
  font-style: normal;
  font-weight: 500;
  font-size: 3.75rem;
  line-height: 4.7rem;
  letter-spacing: -0.07em;
  color: #0d8359;
}
.searchCurrentPrice svg {
  width: 2.4rem;
  height: 1.6rem;
  margin-left: 1rem;
}
.searchResults > .container > div {
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
  margin-bottom: 2rem;
  overflow: auto;
  max-height: 56vh;
  padding-right: 1rem;
}
.subMenu {
  position: absolute;
  top: 100%;
  background-color: #fff;
  border: 1px solid #f2f0f0;
  padding: 2rem 3rem;
  z-index: 10;
  visibility: hidden;
  opacity: 0;
  margin-top: -1rem;
  transition: margin 0.3s ease-in-out, opacity 0.3s ease-in-out;
  max-width: 30rem;
}
.subMenuWrapper {
  display: none;
  background-color: #fff;
  position: absolute;
  width: 100vw;
  top: 17rem;
  z-index: 15;
}
.categorySubMenu {
  background-color: #fff;
  border-top: 1px solid #f2f0f0;
  padding: 4.8rem 0 13rem;
  position: absolute;
  top: 100%;
  width: 100vw;
  margin-left: -50vw;
  margin-right: -50vw !important;
  left: 50%;
  right: 50%;
  z-index: 10;
  visibility: hidden;
  opacity: 0;
  margin-top: -1rem;
  transition: opacity 0.3s, margin 0.3s;
}

.categoryMenu > li:hover .categorySubMenu,
.categoryMenu > li:hover .subMenu {
  visibility: visible;
  opacity: 1;
  margin-top: 0;
  display: block;
}
.categorySubMenu > .container {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;
  row-gap: 5rem;
}
.categorySubMenu > .container > * {
  width: 25%;
}
.categorySubMenu ul,
.subMenu ul {
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
}
.categorySubMenu ul li:first-child a {
  font-style: normal;
  font-weight: 700;
  font-size: 1.6rem;
  line-height: 2.4rem;
  letter-spacing: -0.02em;
  color: #303030;
  margin-bottom: 0.3rem;
  opacity: 1;
  width: 95%;
}
.categorySubMenu ul li:first-child a::after {
  display: none;
}

.categorySubMenu ul li a,
.subMenu ul li a {
  font-style: normal;
  font-weight: 500;
  font-size: 1.4rem;
  line-height: 2.4rem;
  letter-spacing: -0.02em;
  color: #303030;
  opacity: 0.7;
  position: relative;
  display: inline-block;
}
.categorySubMenu ul li a::after,
.subMenu ul li a::after {
  content: "";
  position: absolute;
  top: 50%;
  left: -1rem;
  background-color: #0d8359;
  width: 0.4rem;
  height: 0.4rem;
  border-radius: 50%;
  transform: translate(-2rem, -50%);
  opacity: 0;
  transition: all 0.3s;
}
.categorySubMenu ul li a:hover,
.subMenu ul li a:hover {
  color: #0d8359;
}
.categorySubMenu ul li a:hover::after,
.subMenu ul li a:hover::after {
  opacity: 1;
  transform: translate(0, -50%);
}
.subMenuMore {
  width: 100% !important;
}
.blogHover span {
  margin-left: -2rem;
  transition: all 0.4s ease-in-out;
}
.blogHover svg {
  margin-right: -2rem;
  transition: all 0.4s ease-in-out;
}
.menuSeeAll:hover .blogHover span,
.menuSeeAll:hover .blogHover svg,
.blog:hover .blogHover span,
.blog:hover .blogHover svg,
.campaignAds a:hover .blogHover span,
.campaignAds a:hover .blogHover svg {
  margin: 0;
}
.breadcrumb {
  transform: translateY(-3rem);
}
.breadcrumb ul {
  display: flex;
  column-gap: 3rem;
  align-items: center;
}
.breadcrumb ul li a {
  font-style: normal;
  font-weight: 600;
  font-size: 1.3rem;
  line-height: 1.6rem;
  letter-spacing: -0.02em;
  color: #2d483e;
  position: relative;
  transition: all 0.3s;
}
.breadcrumb ul li a:hover {
  color: #0d8359;
}
.breadcrumb ul li:last-child a {
  color: #d2cdcd;
  pointer-events: none;
}
.breadcrumb ul li a::after {
  content: "";
  position: absolute;
  right: -2.1rem;
  top: 50%;
  transform: translateY(-50%);
  width: 1.2rem;
  height: 0.1rem;
  background-color: #2d473e;
}
.breadcrumb ul li:last-child a::after {
  display: none;
}
/* .allProducts {

} */
.allProducts h1 {
  font-style: normal;
  font-weight: 700;
  font-size: 4.8rem;
  line-height: 6rem;
  color: #2f4644;
}
.allProducts p {
  font-style: normal;
  font-weight: 400;
  font-size: 1.4rem;
  line-height: 1.8rem;
  color: #91a8a7;
}
.allProducts .container > div p {
  width: 80%;
}
.allProducts > .container > div {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}
#sort .css-13cymwt-control {
  background-color: #ffffff !important;
  color: #000000;
  font-style: normal;
  font-weight: 600;
  font-size: 1.3rem;
  line-height: 1.6rem;
  letter-spacing: -0.02em;
  position: relative;
  width: auto;
  padding: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  column-gap: 1rem;
  user-select: none;
}
#sort .css-t3ipsp-control {
  background-color: #ffffff !important;
}
#sort .css-13cymwt-control .option,
#sort .css-t3ipsp-control .option {
  display: flex;
  align-items: center;
  column-gap: 0.5rem;
}
#sort .css-13cymwt-control .option svg,
#sort .css-t3ipsp-control .option svg {
  display: block;
}
#sort .option svg {
  display: none;
}
#sort .css-1nmdiq5-menu {
  background: #ffffff !important;
  box-shadow: 0px 8px 20px rgba(196, 196, 196, 0.2) !important;
  border-radius: 2rem !important;
}
#sort .css-1nmdiq5-menu .option {
  font-weight: 600;
  font-size: 1.1rem;
  line-height: 1.4rem;
  letter-spacing: -0.02em;
  font-feature-settings: "pnum" on, "lnum" on, "liga" off, "kern" off;
}
#sort .select-styled::after {
  content: url("./assets/icon/ChevronDown.svg");
  top: 0;
  right: 0;
  width: auto;
  height: auto;
  border: none;
  position: relative;
  transform: rotate(180deg);
  transition: all 0.3s;
}
#sort .select-styled::before {
  content: url("./assets/icon/ArrowBottom2.svg");
  top: 0;
  transform: translateY(2px);
}
#sort .select-styled.active::after {
  transform: rotate(360deg);
}
#sort .select-options {
  background-color: #fff;
  box-shadow: 0px 6px 20px rgba(100, 100, 100, 0.1);
  border-radius: 20px;
  padding: 0 2.8rem;
  min-width: 18rem;
  user-select: none;
}
#sort .select-options li {
  font-style: normal;
  font-weight: 600;
  font-size: 1.1rem;
  line-height: 1.4rem;
  letter-spacing: -0.02em;
  font-feature-settings: "pnum" on, "lnum" on, "liga" off, "kern" off;
  color: #000000;
  border: none;
  padding: 0;
  padding: 0.7rem 0;
}
#sort .select-options li:first-child {
  padding-top: 2rem;
}
#sort .select-options li:last-child {
  padding-bottom: 2rem;
}
#sort .select-options li:hover {
  color: #0d8359;
  background-color: transparent;
}
.productsContent {
  padding-top: 5.5rem;
  padding-bottom: 9.1rem;
  margin-top: 1rem;
  border-top: 1px solid #f2f0f0;
}
.productsContent .container {
  display: flex;
  align-items: flex-start;
  position: relative;
}
.allProducts > .container > .campaignAds {
  display: none;
}
.campaignAds {
  width: 100%;
  margin-bottom: 3.2rem;
}
.campaignAds .blogHover,
.campaignAds .blogBg {
  top: 0;
}
.campaignAds a:hover .blogHover,
.campaignAds a:hover .blogBg {
  opacity: 1;
}
.campaignAds .imgContainer {
  height: 35rem;
  position: relative;
  margin-bottom: 1.2rem;
}
.campaignAds .imgContainer img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.filter {
  width: 22%;
}
.filterTop {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #e6e7ed;
  padding-bottom: 0.9rem;
  margin-bottom: 2.7rem;
}
.filterTop h5 {
  font-style: normal;
  font-weight: 500;
  font-size: 1.7rem;
  line-height: 2.1rem;
  letter-spacing: -0.02em;
  font-feature-settings: "pnum" on, "lnum" on, "liga" off, "kern" off;
  color: #000000;
}
.filterTop button {
  background-color: transparent;
  border: none;
  display: flex;
  align-items: center;
  column-gap: 0.514rem;
  font-style: normal;
  font-weight: 500;
  font-size: 1.1rem;
  line-height: 1.4rem;
  letter-spacing: -0.02em;
  font-feature-settings: "pnum" on, "lnum" on, "liga" off, "kern" off;
  color: #000000;
}
.filter > div:not(:first-child) {
  margin-bottom: 5rem;
}

.filter h4 {
  font-style: normal;
  font-weight: 700;
  font-size: 1.7rem;
  line-height: 2.1rem;
  letter-spacing: -0.02em;
  color: #202e2d;
  margin-bottom: 2rem;
}
.filter .css-13cymwt-control,
.filter .css-t3ipsp-control {
  width: 100% !important;
  height: 6rem;
}
.filter .select2-container {
  width: 100% !important;
}
.filter .select2-container--default .select2-selection--single {
  width: 100%;
  height: 4.585rem;
}
.filter
  .select2-container
  .select2-selection--single
  .select2-selection__rendered {
  height: 4.585rem;
}
.filter .dimensionFilter form {
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
}
.select2-results__options .select2-results__option:first-child {
  display: none;
}
.select2-container--open .select2-dropdown--below,
.select2-container--open .select2-dropdown--above {
  margin-top: 0.615rem;
  background-color: #f4f7f7;
  border-radius: 5px;
  border: none;
  overflow: hidden;
  position: relative;
  box-shadow: 1px 1px 6px rgba(0, 0, 0, 0.1);
}
.select2-container--open .select2-dropdown--above {
  margin-top: -0.615rem;
}
.select2-container--default .select2-search--dropdown .select2-search__field {
  border: none;
  font-style: normal;
  font-weight: 500;
  font-size: 1.6rem;
  line-height: 2rem;
  letter-spacing: -0.02em;
  color: #202e2d;
  padding-left: 4.7rem;
  position: relative;
  height: 4.3rem;
}
.select2-container--default
  .select2-search--dropdown
  .select2-search__field::placeholder {
  color: rgba(32, 46, 45, 0.4);
}

.select2-container--open .select2-dropdown--below::before,
.select2-container--open .select2-dropdown--above::before {
  content: url("./assets/icon/search.svg");
  position: absolute;
  left: 2rem;
  top: 1.637rem;
  z-index: 2;
}

.select2-search--dropdown {
  border-bottom: 1px solid #e4ecec;
}
.select2-container--default .select2-search--dropdown .select2-search__field {
  background-color: transparent;
}
.select2-results {
  padding: 1.3rem 1.7rem 1.9rem 2.2rem;
}
.select2-results__options {
  display: flex;
  flex-direction: column;
  row-gap: 1.2rem;
  max-height: 14.8rem !important;
  overflow: auto;
}
.select2-results__options::-webkit-scrollbar {
  width: 2px;
}
.select2-results__options::-webkit-scrollbar-thumb {
  background-color: #0d8359;
  border-radius: 10rem;
}
.select2-results__option {
  padding: 0;
  font-style: normal;
  font-weight: 500;
  font-size: 1.6rem;
  line-height: 2rem;
  letter-spacing: -0.02em;
  color: #000000;
}
.select2-container--default
  .select2-results__option--highlighted[aria-selected] {
  background-color: transparent;
  color: #0d8359;
}
.select2-container--default .select2-results__option[aria-selected="true"] {
  color: #0d8359;
  background-color: transparent;
}

.priceFilter {
  display: flex;
  flex-direction: column;
  row-gap: 1.2rem;
}
.priceFilter .radioGroup input {
  display: none;
}
.priceFilter .radioGroup label {
  display: flex;
  align-items: center;
  column-gap: 0.9rem;
  font-style: normal;
  font-weight: 500;
  font-size: 1.5rem;
  line-height: 1.9rem;
  letter-spacing: -0.02em;
  color: #91a8a7;
  height: 2rem;
  transition: all 0.3s;
}
.priceFilter label > :first-child {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.priceFilter .radioGroup label :first-child > span {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 1.3rem;
  height: 1.3rem;
  border-radius: 50%;
  border: 1px solid #91a8a7;
  transition: all 0.3s;
  position: relative;
}
.priceFilter .radioGroup label:hover {
  color: #0d8359;
}
.priceFilter .radioGroup label:hover :first-child > span {
  border-color: #0d8359;
}
.priceFilter .radioGroup input:checked + label {
  color: #0d8359;
}
.priceFilter .radioGroup input:checked + label :first-child > span {
  background-color: #0d8359;
  border-color: #0d8359;
}
.priceFilter .radioGroup input:checked + label :first-child > span img {
  width: 70%;
}
.carType {
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
}
.carType label {
  font-style: normal;
  font-weight: 500;
  font-size: 1.5rem;
  line-height: 1.9rem;
  letter-spacing: -0.02em;
  color: #91a8a7;
}
.carType .checkboxGroup {
  display: flex;
  flex-direction: column;
  row-gap: 1.5rem;
}
.carType .checkboxGroup > div {
  display: flex;
  align-items: center;
  column-gap: 0.9rem;
}
.carType .inputGroup {
  display: flex;
  margin-bottom: 2rem;
}
.carType .inputGroup input {
  background-color: #f4f7f7;
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
  height: 4.4rem;
  border: none;
  padding-left: 2.285rem;
  font-style: normal;
  font-weight: 500;
  font-size: 1.5rem;
  line-height: 1.9rem;
  letter-spacing: -0.02em;
  color: #000000;
  width: 100%;
}
.carType .inputGroup input::placeholder {
  color: #91a8a7;
}
.carType .inputGroup button {
  background-color: #f4f7f7;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
  padding-right: 2.2rem;
}
.filter > div:last-child h4 {
  margin-bottom: 0.9rem;
}
.filter > div:last-child .checkboxGroup {
  max-height: 20rem;
  overflow: auto;
}
.checkboxGroup > div input {
  display: none;
}
.checkboxGroup > div span {
  border: 1px solid #91a8a7;
  border-radius: 3px;
  width: 1.31rem;
  min-width: 1.31rem;
  height: 1.31rem;
  min-height: 1.31rem;
  transition: all 0.3s;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.3s;
}
.detailSearchContent .checkboxGroup > div span {
  width: 4.4rem;
  height: 4.4rem;
  border-radius: 50%;
}
.detailSearchContent .checkboxGroup > div span svg {
  width: 80%;
  height: 80%;
}
.detailSearchContent .checkboxGroup > div input:checked + label span svg path {
  fill: #ffffff;
}
.checkboxGroup > div label {
  transition: all 0.3s;
  display: flex;
  align-items: center;
  column-gap: 0.9rem;
}
.checkboxGroup > div:hover label {
  color: rgba(13, 131, 89, 1);
}
.checkboxGroup > div:hover span {
  border-color: rgba(13, 131, 89, 1);
}
.checkboxGroup > div input:checked + label span {
  border-color: rgba(13, 131, 89, 1);
  background-color: rgba(13, 131, 89, 1);
}
.checkboxGroup > div input:checked + label {
  color: rgba(13, 131, 89, 1);
}
.checkboxGroup > div span svg {
  scale: 0.8;
}
.checkboxGroup::-webkit-scrollbar {
  width: 0.2rem;
  background-color: #f4f7f7;
  border-radius: 10rem;
}
.checkboxGroup::-webkit-scrollbar-thumb {
  width: 0.2rem;
  background-color: #0d8359;
  border-radius: 10rem;
}
.productCards {
  padding-left: 3.6rem;
  width: 78%;
}
.productCards .infinite-scroll-component__outerdiv {
}
.productCards .infinite-scroll-component {
  display: flex;
  flex-wrap: wrap;
  row-gap: 2rem;
  column-gap: 2rem;
}
.productCards .product {
  width: calc(100% / 3 - 4rem / 3) !important;
}
.product {
  height: 100%;
}
.products .lazy-load-image-background.blur.lazy-load-image-loaded {
  display: flex !important;
  justify-content: center;
  align-items: center;
  height: 100%;
}
.lazy-load-image-background.blur.lazy-load-image-loaded {
  display: flex !important;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}
.lazy-load-image-background.blur.lazy-load-image-loaded > img {
  height: 90%;
}
.productDetailsPage {
  padding-top: 3rem;
}
.productDetailsPage + .services + .products + .products .seeAll {
  display: none;
}
.productDetailsPage .videoContainer {
  height: 59rem;
}
.productDetailsPage h1 {
  font-style: normal;
  font-weight: 700;
  font-size: 4rem;
  line-height: 5rem;
  letter-spacing: -0.03em;
  color: #2f4644;
}
.similarProduct .seeAll {
  display: none;
}
.rating-share {
  display: flex;
  align-items: center;
  column-gap: 6.8rem;
  padding-top: 1.3rem;
  padding-bottom: 2.5rem;
}
.productRating {
  display: flex;
  align-items: center;
  column-gap: 0.53rem;
  position: relative;
}
.productRating span {
  font-style: normal;
  font-weight: 400;
  font-size: 1.17rem;
  line-height: 1.5rem;
  letter-spacing: -0.01em;
  color: #0e192d;
  opacity: 0.6;
}
.productRating > div {
  display: flex;
  align-items: center;
  column-gap: 0.25rem;
}
.productRating button {
  background-color: transparent;
  border: none;
  padding: 0;
}
.productRating button path {
  transition: all 0.3s;
}
.productRating button:hover path {
  fill: #0d8359;
}
.productRating::after {
  content: "";
  position: absolute;
  width: 0.113rem;
  height: 2rem;
  background-color: #dedddd;
  right: -3.4rem;
}
.productShare {
  display: flex;
  align-items: center;
  column-gap: 1.2rem;
}
.productShare > div {
  display: flex;
  align-items: center;
  column-gap: 0.9rem;
}
.productShare > span {
  font-style: normal;
  font-weight: 500;
  font-size: 1.08rem;
  line-height: 116.1%;
  letter-spacing: -0.01em;
  color: #3a3a3a;
}
.productShare a path {
  transition: all 0.3s;
}
.productShare a:hover path {
  fill: #0d8359;
}
.productDetailContent {
  display: flex;
  align-items: flex-start;
  padding-bottom: 5.6rem;
}
.productDetailLeft {
  width: 40%;
  position: relative;
}
.productDetailLeft .imgContainers {
  border: 1px solid #f2f0f0;
  height: 55rem;
  display: flex;
  justify-content: center;
  align-items: center;
}
.productDetailLeft .imgContainers img {
  height: 95%;
}
.productDetailLeft .react-tabs__tab-list {
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 1.275rem;
  padding-top: 0.8rem;
  border-bottom: none;
}

.productDetailLeft .react-tabs__tab {
  width: 4.9rem;
  height: 4.9rem;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  border-radius: 50%;
  transition: all 0.3s;
}
.productDetailLeft .react-tabs__tab--selected {
  border: 1px solid #e22626;
  transform: scale(1.1);
}
.react-tabs__tab img {
  height: 100%;
}
.prodInfo {
  position: absolute;
  top: 2rem;
  left: 2rem;
  display: flex;
  align-items: center;
  column-gap: 1.3rem;
}
.prodInfo .newProduct,
.prodInfo .freeMounting,
.prodInfo .taksit,
.prodInfo .discount {
  position: relative;
  top: unset;
  left: unset;
  right: unset;
}
.productDetailRight {
  padding-top: 4.1rem;
  padding-left: 4.4rem;
  width: 60%;
}
.prodRightTop {
  display: flex;
  align-items: flex-start;
  margin-bottom: 1.7rem;
}
.prodRightTop > div:first-child {
  width: 45%;
  margin-top: 2rem;
}
.prodRightTop > div:first-child > div {
  display: flex;
  align-items: center;
  column-gap: 2.8rem;
  padding-bottom: 2.1rem;
}
.prodRightTop > div:first-child > div .imgContainer {
}
.prodRightTop > div:first-child > div .imgContainer img {
  width: 12rem;
  object-fit: contain;
}
.prodRightTop p {
  font-style: normal;
  font-weight: 400;
  font-size: 1.5rem;
  line-height: 161.1%;
  letter-spacing: 0.01em;
  color: #2a2a30;
  opacity: 0.8;
}
.prodRightTop > div:last-child {
  width: 55%;
  padding-left: 1.3rem;
}
.buyNowCard {
  border: 1px solid #e7e4e4;
  border-radius: 8px;
  padding: 2.1rem 2.9rem 4.5rem;
}
.buyNowTop {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 2.05rem;
}
.buyNowCards {
  display: flex;
  align-items: center;
  column-gap: 0.4rem;
}
.buyNowCards > div {
  width: 4.7rem;
  height: 4.7rem;
  border-radius: 50%;
  border: 1.2234px solid #e2e2e9;
  display: flex;
  justify-content: center;
  align-items: center;
}
.buyNowPrice {
  display: flex;
  align-items: flex-end;
  column-gap: 3rem;
  width: 100%;
}
.buyNowPrice > span:first-child {
  font-style: normal;
  font-weight: 600;
  font-size: 3.37rem;
  line-height: 116.1%;
  letter-spacing: -0.03em;
  color: #212121;
}
.buyNowPrice span {
  display: flex;
  align-items: baseline;
  column-gap: 0.4rem;
}
.discountPrice span {
  position: relative;
}
.discountPrice span {
  font-style: normal;
  font-weight: 400;
  font-size: 2.11rem;
  line-height: 116.1%;
  letter-spacing: -0.03em;
  color: #252525;
}
.buyNowPrice span:last-child > * {
  opacity: 0.7;
}
.discountPrice span::before {
  content: "";
  position: absolute;
  top: 50%;
  width: 100%;
  height: 0.143rem;
  background-color: #292929;
  transform: rotate(10deg);
}
.buyNowBottom {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.buyNowBottom button {
  background-color: transparent;
  border: 1.33702px solid #e2e5e4;
  height: 4.8rem;
  border-radius: 8px;
  width: 20%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.buyNowBottom a {
  background-color: #0d8359;
  height: 4.8rem;
  border-radius: 8px;
  width: 77%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-style: normal;
  font-weight: 600;
  font-size: 2.05rem;
  line-height: 2.6rem;
  text-align: center;
  letter-spacing: -0.03em;
  color: #ffffff;
  transition: all 0.3s;
}
.buyNowBottom a:hover {
  background-color: #0a6142;
}
.buyNowBottom a:active {
  background-color: #063826;
}
.notInStock {
  background-color: #636363 !important;
  pointer-events: none;
}
.credit {
  padding: 1.3rem 4.2rem 4.3rem;
  background: #faf9f9;
  border: 1px solid #f2f1f1;
  border-radius: 8px;
}

.credit h3 {
  font-style: normal;
  font-weight: 700;
  font-size: 1.6rem;
  line-height: 161.1%;
  text-align: center;
  letter-spacing: -0.02em;
  color: #181818;
}

.creditContent {
  padding: 0 1rem;
}

.creditHeader {
  display: flex;
  align-items: center;
  border-bottom: 1px solid #dfdfea;
  padding-bottom: 0.38rem;
}

.creditHeader span {
  font-style: normal;
  font-weight: 400;
  font-size: 1.5rem;
  line-height: 161.1%;
  letter-spacing: -0.02em;
  color: #1d1d1e;
  text-align: center;
}
.creditBody .card:last-child {
  border: none;
  padding-bottom: 0;
}
.card {
  padding: 1.8rem 0;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #f3f1f1;
}
.card > div:first-child {
  display: flex;
  column-gap: 2.8rem;
  align-items: center;
  width: 25%;
}
.card > div:nth-child(2) {
  width: 15%;
}
.card > div:nth-child(3),
.card > div:nth-child(4),
.card > div:nth-child(5) {
  width: 20%;
}
.card > div:not(:first-child) {
  justify-content: center;
  display: flex;
}
.card span {
  font-size: 1.7rem;
  line-height: 161.1%;
  letter-spacing: -0.02em;
  color: #1d1d1e;
}
.cardImg {
  min-width: 4.5rem;
  width: 4.5rem;
  min-height: 2.8rem;
  height: 2.8rem;
}
.cardImg img {
  width: 100%;
  height: 100%;
}
.card select {
  border: 1px solid #f3f1f1;
  border-radius: 8px;
  background: #ffffff;
  height: 3.5rem;
  width: 9rem;
  padding-left: 2rem;
}
.creditBody {
  display: flex;
  flex-direction: column;
  max-height: 30rem;
  overflow: auto;
  padding: 0.5rem 0;
}
.creditBody::-webkit-scrollbar {
  width: 2px;
  background-color: #f2f1f1;
  border-radius: 10rem;
}
.creditBody::-webkit-scrollbar-thumb {
  background-color: #0d8359;
  border-radius: 10rem;
}
.creditBody form .radioGroup {
  width: 100%;
}

.creditHeader span:first-child,
.creditBody label > :first-child {
  width: 25%;
  display: flex;
  align-items: center;
}

.creditHeader span:nth-child(2),
.creditBody label > :nth-child(2) {
  width: 15%;
}

.creditHeader span:nth-child(3),
.creditBody label > :nth-child(3) {
  width: 20%;
}

.creditHeader span:nth-child(4),
.creditBody label > :nth-child(4) {
  width: 20%;
}

.creditHeader span:last-child,
.creditBody label > :last-child {
  width: 20%;
}

.creditBody .radioGroup label {
  display: flex;
  margin-left: 0;
  align-items: center;
}

.creditBody .radioGroup label > span {
  text-align: center;
  display: block;
  font-style: normal;
  font-weight: 500;
  font-size: 1.4rem;
  line-height: 161.1%;
  letter-spacing: -0.02em;
  color: #161616;
}

.creditBody .radioGroup input {
  display: none;
}

.creditBody .radioGroup label :first-child > span {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 1.515rem;
  height: 1.515rem;
  border-radius: 50%;
  border: 1px solid #0b0b4c;
  opacity: 0.24;
  transition: all 0.3s;
  position: relative;
}
.priceFilter .radioGroup label :first-child > span {
  width: 1.8rem;
  height: 1.8rem;
}

.creditBody .radioGroup label :first-child > span img {
  width: 0%;
}

.creditBody .radioGroup input:checked + label :first-child > span {
  transform: scale(1.35);
  background-color: #167d58;
  border-color: #167d58;
  opacity: 1;
}

.creditBody .radioGroup input:checked + label :first-child > span img {
  width: 90%;
}
.credit .buttons {
  display: flex;
  align-items: center;
  margin-top: 2.2rem;
}
.credit .buttons a {
  border: 1px solid #eceeee;
  border-radius: 4px;
  height: 3.6rem;
  padding: 0 2.7rem;
  display: flex;
  justify-content: center;
  align-items: center;
  font-style: normal;
  font-weight: 600;
  font-size: 1.3rem;
  line-height: 116.1%;
  letter-spacing: -0.02em;
  color: #363636;
  margin-right: 1rem;
  transition: all 0.3s;
}
.credit .buttons a:hover {
  border-color: #0d8359;
}

.credit .buttons button {
  padding: 0 2.9rem;
  height: 3.6rem;
  background-color: #0d8359;
  border-radius: 4px;
  border: none;
  font-style: normal;
  font-weight: 600;
  font-size: 1.3rem;
  line-height: 116.1%;
  letter-spacing: -0.02em;
  color: #ffffff;
  margin-right: 3.8rem;
  transition: all 0.3s;
}
.credit .buttons button:hover {
  background-color: #0a6142;
}
.credit .buttons button:active {
  background-color: #063826;
}
#prodInfoTabs {
  display: flex;
  border: 1px solid #ece9e9;
  border-bottom: none;
}
#prodInfoTabs li {
  width: 25%;
  text-align: center;
}
#prodInfoTabs li {
  font-style: normal;
  font-weight: 600;
  font-size: 2.4rem;
  line-height: 116.1%;
  text-align: center;
  letter-spacing: -0.03em;
  color: #695f60;
  border-bottom: 1px solid #ece9e9;
  height: 8rem;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.3s;
}
#prodInfoTabs li {
  border-right: 1px solid #ece9e9;
}
#prodInfoTabs li:last-child {
  border-right: none;
}
#prodInfoTabs li a.inactive {
  border-bottom: 1px solid #ece9e9;
  color: #695f60;
}
#prodInfoTabs .react-tabs__tab {
  border-top: none;
  border-left: none;
}
#prodInfoTabs .react-tabs__tab--selected {
  color: #ca071f;
  border-bottom: 2px solid #ca071f;
  border-radius: 0;
}
#prodInfoTabs .react-tabs__tab:focus:after {
  display: none;
}
.prodInfoTabs {
  border: 1px solid #ece9e9;
  border-top: none;
  padding: 5rem 9rem;
  min-height: 18.3rem;
  margin-bottom: 7.4rem;
}
.isVideo #prodInfoTabs li {
  width: calc(100% / 3);
}
.isVideo #prodInfoTabs li:last-child {
  display: none;
}
.isVideo #prodInfoTabs li:nth-child(3) {
  border-right: none;
}
.isVideo > div:last-child {
  display: none;
}
.prodInfoTabs .row {
  display: block;
}
#specs {
  display: flex;
  justify-content: space-between;
}
#specs > div {
  display: flex;
  align-items: center;
}
#specs > div > div {
  display: flex;
  flex-direction: column;
  row-gap: 1.4rem;
}
#specs > div > div:first-child {
  width: 50%;
}
#specs > div > div:last-child {
  width: 50%;
}
#specs > div span,
#specs > div a {
  font-style: normal;
  font-weight: 500;
  font-size: 1.467rem;
  line-height: 116.1%;
  letter-spacing: -0.03em;
  color: #2a2a30;
  display: inline-block;
  padding-bottom: 0.24rem;
}
#specs > div a span {
  color: #13996a;
  border-bottom: 1px solid #13996a;
  transition: all 0.3s;
}
#specs a path {
  transition: all 0.3s;
}
#specs a:hover span {
  color: #0a6142;
  border-color: #0a6142;
}
#specs a:hover path {
  fill: #0a6142;
}
#info p {
  font-style: normal;
  font-weight: 400;
  font-size: 1.5rem;
  line-height: 161.1%;
  letter-spacing: 0.01em;
  color: #2a2a30;
  opacity: 0.8;
  margin-bottom: 1.6rem;
}
#info p strong {
  margin-bottom: 1rem;
  margin-left: 0.4rem;
}
#sizes {
  display: flex;
  column-gap: 17.2rem;
}
#sizes > div {
  display: flex;
  flex-direction: column;
  row-gap: 1.65rem;
}
#sizes a span {
  font-style: normal;
  font-weight: 500;
  font-size: 14.6702px;
  line-height: 116.1%;
  letter-spacing: -0.03em;
  color: #bdbdbd;
  padding-bottom: 0.2rem;
  border-bottom: 1px solid rgba(181, 174, 174, 0.7);
  transition: all 0.3s;
}
#sizes a:hover span {
  color: #0a6142;
  border-color: #0a6142;
}
.similarProducts {
  padding-bottom: 0;
}
.campaignsPage {
  padding-top: 3rem;
}
.campaignsPage h1 {
  font-style: normal;
  font-weight: 700;
  font-size: 4.8rem;
  line-height: 6rem;
  color: #2f4644;
  margin-bottom: 0.8rem;
}
.campaignsPage p {
  font-style: normal;
  font-weight: 400;
  font-size: 1.4rem;
  line-height: 1.8rem;
  color: #91a8a7;
}
.campaigns {
  margin-top: 1.8rem;
  padding-top: 3.2rem;
  padding-bottom: 7.8rem;
  border-top: 1px solid #f2f0f0;
}
.campaigns .container {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  row-gap: 5rem;
}
.campaigns .container > div {
  width: calc(100% / 3);
  padding: 0 1rem;
}
.campaigns .container > div:first-child,
.campaigns .container > div:nth-child(3n + 1) {
  padding-left: 0;
}
.campaigns .container > div:nth-child(3n) {
  padding-right: 0;
}
.campaignImg {
  height: 42.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  margin-bottom: 1.8rem;
  position: relative;
}
.campaignImg img {
  width: 100%;
}
.campaign h4,
.campaignAds a h4 {
  font-style: normal;
  font-weight: 500;
  font-size: 2rem;
  line-height: 2.8rem;
  letter-spacing: -0.02em;
  color: #273332;
  transition: all 0.3s;
  width: 80%;
}
.campaign:hover .blogBg,
.campaign:hover .blogHover {
  opacity: 1;
}
.campaign:hover .blogHover span,
.campaign:hover .blogHover svg {
  margin: 0;
}
.campaign:hover h4 {
  color: #0a6142;
}
.alert-body h3 {
  font-weight: 700;
  font-size: 4.4rem;
  line-height: 5.5rem;
  text-align: center;
  color: #000000;
  margin-bottom: 5rem;
}
.aboutUs {
  display: flex;
  align-items: center;
  padding: 0 11.4rem 7.2rem;
}
.aboutPage h1 {
  font-style: normal;
  font-weight: 700;
  font-size: 4.8rem;
  line-height: 6rem;
  letter-spacing: -0.03em;
  color: #2f4644;
  margin-bottom: 1.5rem;
}
.aboutPage h2 {
  font-style: normal;
  font-weight: 700;
  font-size: 4.4rem;
  line-height: 6rem;
  letter-spacing: -0.03em;
  color: #2f4644;
  margin-bottom: 1.5rem;
}
.aboutPage strong {
  font-style: normal;
  font-weight: 700;
  font-size: 2rem;
  line-height: 2.4rem;
  letter-spacing: -0.03em;
  color: #2f4644;
  margin-bottom: 1.4rem;
}
.aboutPage p {
  font-style: normal;
  font-weight: 400;
  font-size: 1.7rem;
  line-height: 165%;
  letter-spacing: -0.02em;
  color: #1f1f38;
  width: 90%;
  margin-bottom: 1.6rem;
}
.aboutUs p {
  width: 78%;
}
.aboutUs > div:first-child {
  width: 55%;
}
.aboutUs > div:last-child {
  width: 45%;
}
.aboutVideo {
  height: 64.7rem;
  overflow: hidden;
  justify-content: center;
  align-items: center;
  position: relative;
}
.aboutVideo img {
  object-fit: cover;
  height: 100%;
  width: 100%;
}
.videoBg {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: #bab3b3;
  mix-blend-mode: multiply;
}
.aboutVideo a,
.blogDetailVideo a {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.aboutVideo a span,
.blogDetailVideo a span {
  background-color: #fff;
  border-radius: 3rem;
  height: 6rem;
  font-style: normal;
  font-weight: 700;
  font-size: 1.48rem;
  line-height: 161.1%;
  text-align: center;
  letter-spacing: 0.02em;
  text-transform: uppercase;
  color: #3a3a3a;
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 0.526rem;
  padding: 0 5rem;
}
.statistics {
  display: flex;
  padding: 0 11.4rem;
}
.statistics > div {
  width: 25%;
  display: flex;
  flex-direction: column;
  row-gap: 0.6rem;
  border-right: 1px solid #ece9e9;
  padding-left: 6.5rem;
}
.statistics > div:first-child {
  padding-left: 0;
}
.statistics > div:last-child {
  border: none;
}
.statistics > div > span:first-child {
  font-style: normal;
  font-weight: 500;
  font-size: 5.15rem;
  line-height: 135%;
  letter-spacing: -2px;
  color: #e22626;
}
.statistics > div > span:last-child {
  font-style: normal;
  font-weight: 500;
  font-size: 1.5rem;
  line-height: 135%;
  color: #41474c;
  width: 80%;
}
.missionVision {
  padding: 10rem 11.4rem 12rem;
  display: flex;
  align-items: center;
}
.missionVision > div:first-child {
  width: 45%;
}
.missionVision > div:last-child {
  width: 55%;
  padding-left: 9rem;
}
.missionVision .imgContainer {
  height: 64.7rem;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  position: relative;
}
.missionVision .imgContainer::after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: #bab3b3;
  mix-blend-mode: multiply;
}
.missionVision .imgContainer img {
  object-fit: cover;
  height: 100%;
}
.certificates {
  padding: 0 11.4rem 11rem;
}
.certificates h1 {
  margin-bottom: 3.9rem;
}
.certificates > div {
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  row-gap: 2.4rem;
  column-gap: 1.6rem;
}
.certificate {
  width: calc(100% / 5 - (4 * 1.6rem) / 5);
}
.certificateImg {
  height: 25rem;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1.5px solid rgb(84, 76, 163);
  padding: 1rem;
}
.certificateImg img {
  /* height: 100%; */
  width: 100%;
  /* object-fit: cover; */
  transition: all 0.4s ease-in-out;
}
.certificate a:hover img {
  transform: scale(1.05);
}
.ourTeam {
  padding: 0 11.4rem 10rem;
  display: flex;
  align-items: center;
}
.ourTeam > div:first-child {
  width: 55%;
}
.ourTeam > div:last-child {
  width: 45%;
}
.ourTeam p {
  width: 78%;
}
.ourTeam .imgContainer {
  height: 64.7rem;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  position: relative;
}
.ourTeam .imgContainer img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}
.ourTeam .imgContainer::after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: #bab3b3;
  mix-blend-mode: multiply;
}
.brandsPage {
  padding-top: 3rem;
  padding-bottom: 7.3rem;
}
.brandsPage h1 {
  font-style: normal;
  font-weight: 800;
  font-size: 3.9rem;
  line-height: 4.9rem;
  color: #000000;
  margin-bottom: 2.7rem;
}
.brandsPage .container > div {
  display: flex;
  flex-wrap: wrap;
  border-top: 1px solid #e7e4e4;
  border-left: 1px solid #e7e4e4;
}
.brandsPage .brand {
  width: calc(100% / 6);
  border-bottom: 1px solid #e7e4e4;
  border-right: 1px solid #e7e4e4;
  padding: 3.3rem 4rem 2rem;
  row-gap: 2.3rem;
  position: relative;
  overflow: hidden;
}
.brandsPage .brand span {
  font-style: normal;
  font-weight: 500;
  font-size: 1.33rem;
  line-height: 170%;
  text-align: center;
  letter-spacing: -0.02em;
  color: #000000;
}
.brandsPage .brand::after {
  content: "";
  position: absolute;
  left: -10rem;
  bottom: -10rem;
  width: 0;
  height: 0;
  background-color: rgba(181, 174, 174, 0.1);
  z-index: -1;
  border-radius: 50%;
  transition: all 0.6s linear;
}
.brandsPage .brand:hover::after {
  width: 50rem;
  height: 50rem;
}
.blogsPage {
  padding: 4rem 0 11.3rem;
}
.blogsPage h1,
.blogDetailsPage h1 {
  font-style: normal;
  font-weight: 800;
  font-size: 3.9rem;
  line-height: 4.9rem;
  color: #000000;
  margin-bottom: 3rem;
}
.blogs {
  display: flex;
  flex-wrap: wrap;
  row-gap: 6.4rem;
}
.blogs > div {
  width: calc(100% / 3);
  padding: 0 1rem;
}
.blogs > div:first-child,
.blogs > div:nth-child(3n + 1) {
  padding-left: 0;
}
.blogs > div:nth-child(3n) {
  padding-right: 0;
}
.blogDetailsPage {
  padding: 4rem 0 10rem;
}
.campaignDetailsPage {
  padding-bottom: 0;
}
.blogDetailsPage h1 {
  margin-bottom: 1.9rem;
}
.blogDate {
  display: flex;
  align-items: center;
  column-gap: 3rem;
  margin-bottom: 3.6rem;
}
.blogDate span {
  font-style: normal;
  font-weight: 500;
  font-size: 1.4rem;
  line-height: 1.8rem;
  letter-spacing: -0.01em;
  color: #636363;
  position: relative;
}
.blogDate span:first-child::after {
  content: "";
  position: absolute;
  right: -1.5rem;
  top: 50%;
  transform: translateY(-50%);
  width: 1px;
  height: 1.2rem;
  background-color: #e4e3e3;
}
.blogTopImg {
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 60rem;
}
.blogTopImg img {
  height: 100%;
  max-width: 100%;
}
.blogText {
  padding: 7.7rem 0 7.7rem 11rem;
}
.blogText h2 {
  font-style: normal;
  font-weight: 700;
  font-size: 3.6rem;
  line-height: 122%;
  letter-spacing: -0.02em;
  color: #0e192d;
  margin-bottom: 3.4rem;
}
.blogText p,
.supportBody p,
.supportBody li {
  font-style: normal;
  font-weight: 500;
  font-size: 1.9rem;
  line-height: 174%;
  letter-spacing: -0.02em;
  color: #0e192d;
  width: 80%;
  margin-bottom: 1.6rem;
}
.supportBody li {
  margin-bottom: 1.4rem;
}
.blogDetailSwiper .swiper-slide {
  height: 63.9rem;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}
.blogDetailSwiper .swiper-wrapper {
  z-index: 1;
}
.blogDetailSwiper .swiper-slide img {
  height: 100%;
  width: 100%;
}
.blogDetailSlider {
  position: relative;
}
.blogDetailSwiper {
  position: unset;
}
.blogDetailSwiper .swiper-navigation {
  position: absolute;
  bottom: -2.5rem;
  left: 50%;
  transform: translateX(-50%);
  z-index: 2;
  display: flex;
  align-items: center;
  column-gap: 2.1rem;
}
.blogDetailSwiper .swiper-button-next,
.blogDetailSwiper .swiper-button-prev {
  position: relative;
  left: unset;
  top: unset;
  right: unset;
  bottom: unset;
  width: 8rem;
  height: 8rem;
  border-radius: 3rem;
  background: #ffffff;
  box-shadow: 5px 7px 19px rgba(189, 181, 181, 0.21);
}
.blogDetailSwiper .swiper-button-next::after,
.blogDetailSwiper .swiper-button-prev::after {
  content: "";
}
.blogText h3 {
  font-style: normal;
  font-weight: 700;
  font-size: 2.4rem;
  line-height: 174%;
  letter-spacing: -0.02em;
  color: #0e192d;
  margin-bottom: 1.6rem;
}
.blogText ul {
  display: flex;
  flex-direction: column;
  row-gap: 1.6rem;
  list-style: disc;
  padding-left: 2.5rem;
}
.blogText ul li {
  font-style: normal;
  font-weight: 500;
  font-size: 1.9rem;
  line-height: 174%;
  letter-spacing: -0.02em;
  color: #0e192d;
  width: 75%;
}
.blogDetailVideo {
  height: 64rem;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  position: relative;
  margin-bottom: 2.4rem;
}
.blogDetailVideo img {
  height: 100%;
  object-fit: cover;
  width: 100%;
}
.blogShare {
  position: relative;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.blogShare::before {
  content: "";
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  width: 100%;
  height: 1px;
  background-color: #e2dcdc;
  z-index: -1;
}
.blogShare > div {
  background-color: #fff;
  padding-left: 4rem;
  display: flex;
  column-gap: 2.4rem;
  align-items: center;
}
.blogShare > div > span {
  font-style: normal;
  font-weight: 500;
  font-size: 1.48rem;
  line-height: 161.1%;
  text-align: center;
  letter-spacing: 0.02em;
  text-transform: uppercase;
  color: #000000;
  opacity: 0.3;
}
.blogShare > div > div {
  display: flex;
  column-gap: 1rem;
  align-items: center;
}
.blogShare > div > div a,
.blogShare > div > div button {
  width: 9.6rem;
  height: 4.2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  border: 1px solid #e8e8f2;
  border-radius: 2.1rem;
  transition: all 0.3s;
  position: relative;
  overflow: hidden;
}
.blogShare path {
  transition: all 0.3s;
}
.blogShare a:hover,
.blogShare button:hover {
  border-color: #0d8359;
}
.blogShare a:hover path,
.blogShare button:hover path {
  fill: #0d8359;
}
.blogShare button span {
  position: absolute;
  display: block;
  opacity: 0;
  font-style: normal;
  font-weight: 500;
  font-size: 1.4rem;
  line-height: 1.4rem;
  letter-spacing: -0.02em;
  color: #fff;
  transition: all 0.4s linear;
  z-index: 2;
}
.copyLink::after {
  content: "";
  position: absolute;
  left: -2rem;
  bottom: -3rem;
  height: 0;
  width: 0;
  border-radius: 50%;
  background-color: #0d8359;
  transition: all 0.4s linear;
  z-index: 1;
}
.copyLink svg {
  transition: all 0.4s linear;
}
.copyLink.active {
  border-color: #0d8359;
}
.copyLink.active svg {
  opacity: 0;
}
.copyLink.active span {
  opacity: 1;
}
.copyLink.active::after {
  width: 13rem;
  height: 13rem;
}
.blogDetailsPage + .products {
  padding-top: 0;
  padding-bottom: 10rem;
}
.contactPage {
  padding-top: 3rem;
  padding-bottom: 7.5rem;
}
.contactPage h1 {
  font-style: normal;
  font-weight: 700;
  font-size: 3.9rem;
  line-height: 4.9rem;
  color: #000000;
  margin-bottom: 6rem;
}
.contact {
  display: flex;
  justify-content: space-between;
  padding-bottom: 5rem;
}
.contact .headerSocials {
  display: flex;
  align-items: center;
  column-gap: 2.8rem;
}
.contact .headerSocials a:last-child svg {
  width: 3.6rem;
  height: 3.6rem;
}
.contact .phoneNumber {
  font-style: normal;
  font-weight: 800;
  font-size: 2.87rem;
  line-height: 3.6rem;
  color: #182322;
}
.contact .phoneNumber:hover {
  color: #0d8359;
}
.contact .callOrder {
  font-style: normal;
  font-weight: 700;
  font-size: 1.92rem;
  line-height: 2.4rem;
  color: #ffffff;
}
.contact .headerAddress {
  display: flex;
  flex-direction: column;
  row-gap: 0.6rem;
}
.contact .headerAddress span,
.contact .headerAddress a {
  font-style: normal;
  font-weight: 400;
  font-size: 1.65rem;
  line-height: 2.1rem;
  letter-spacing: -0.03em;
  color: #000000;
  column-gap: 1rem;
  row-gap: 0;
  display: flex;
}
.contact .contactNumbers {
  display: flex;
  flex-direction: column;
  row-gap: 0.4rem;
}
.contact .contactNumbers a {
  font-style: normal;
  font-weight: 400;
  font-size: 1.73rem;
  line-height: 2.2rem;
  letter-spacing: -0.03em;
  color: #000000;
  display: flex;
  align-items: center;
  column-gap: 0.63rem;
  transition: all 0.3s;
}
.contactNumbers path {
  transition: all 0.3s;
}
.contactNumbers a:hover {
  color: #0d8359;
}
.contactNumbers a:hover path {
  fill: #0d8359;
}
.contactMap {
  height: 52rem;
  margin-bottom: 7.5rem;
  overflow: hidden;
  border-radius: 12px;
}
.contactMap iframe {
  width: 100%;
  height: 100%;
}
.contactUs {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.contactUsLeft,
.contactUsRight {
  width: 49%;
}
.contactUsLeft {
  padding: 4.2rem 3.4rem;
  border: 1px solid #e1e2ea;
  border-radius: 12px;
}
.contactUsLeft .imgContainer {
  height: 71.3rem;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 12px;
}
.contactUsLeft .imgContainer img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}
.contactUsRight {
  border: 1px solid #e1e2ea;
  border-radius: 12px;
  padding: 7rem 7.7rem 8.8rem;
}
.contactUsRight h3 {
  font-style: normal;
  font-weight: 600;
  font-size: 3.2rem;
  line-height: 4rem;
  letter-spacing: -0.02em;
  color: #000000;
  text-align: center;
  margin-bottom: 2.2rem;
}
.contactForm label {
  font-style: normal;
  font-weight: 400;
  font-size: 1.2rem;
  line-height: 1.5rem;
  letter-spacing: -0.02em;
  color: #000000;
  opacity: 0.48;
  margin-bottom: 0.6rem;
  display: block;
  padding-left: 1rem;
}
.contactForm input,
.contactForm textarea {
  background: #f4f7f7;
  border-radius: 8px;
  border: none;
  font-style: normal;
  font-weight: 500;
  font-size: 1.6rem;
  line-height: 2rem;
  letter-spacing: -0.02em;
  color: #000000;
  margin-bottom: 2.4rem;
  transition: all 0.3s;
  border: none;
}
.contactForm input::placeholder,
.contactForm textarea::placeholder {
  color: rgba(0, 0, 0, 0.3);
}
.contactForm input {
  width: 100%;
  height: 5.6rem;
  padding: 0 2.4rem;
}
.contactForm textarea {
  width: 100%;
  height: 17.3rem;
  resize: none;
  padding: 1.6rem 2.4rem;
}
.contactForm input:focus,
.contactForm textarea:focus {
  color: #0d8359;
}
.contactForm input + input {
  padding-right: 0;
}
.contactForm input:focus + input {
  color: #0d8359;
}
.contactForm button {
  background: #0d8359;
  border-radius: 8px;
  border: none;
  width: 100%;
  height: 5.7rem;
  font-style: normal;
  font-weight: 700;
  font-size: 1.8rem;
  line-height: 2.3rem;
  text-align: center;
  letter-spacing: -0.02em;
  text-transform: uppercase;
  color: #ffffff;
  transition: all 0.3s linear;
}
.contactForm button:hover {
  background-color: #0a6142;
}
.contactForm button:active {
  background-color: #063826;
}
.contactPhone {
  display: flex;
  flex-direction: row-reverse;
}
.contactPhone input:first-child {
  width: 82%;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-left: none;
  min-width: fit-content;
  text-align: left;
}
.contactPhone input:last-child {
  width: 18%;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-right: 1px solid #e1e2ea;
  padding: 0;
  text-align: center;
}
.supportPage {
  padding: 3rem 0 8rem;
}
.supportPage h1 {
  font-style: normal;
  font-weight: 700;
  font-size: 3.9rem;
  line-height: 4.9rem;
  color: #000000;
  margin-bottom: 3.3rem;
}

.supportContent {
  display: flex;
  flex-direction: column;
}
.supportContent .MuiPaper-root {
  border-radius: 0 !important;
  border: none !important;
  box-shadow: none !important;
}
.supportContent .MuiButtonBase-root {
  padding: 0;
}
.supportContent .MuiTypography-root {
  width: 100%;
}
.supportContent .MuiAccordionSummary-content {
  margin: 0;
}
.supportHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding-left: 5.9rem;
  height: 11.2rem;
  cursor: pointer;
  border: 1px solid #e1e2ea;
  border-top: none;
  transition: all 0.3s;
}

.supportContent .MuiPaper-root:first-child .supportHeader {
  border-top: 1px solid #e1e3ea;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}
.supportContent .MuiPaper-root:last-child .supportHeader {
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}

.supportHeader.active {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.supportHeader .plus {
  position: relative;
  width: 1.3rem;
  height: 0.2rem;
  background-color: #0d8359;
  border-radius: 10rem;
}

.supportHeader .plus::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  content: "";
  background-color: #0d8359;
  transition: 0.3s;
  border-radius: 10rem;
}

.supportHeader .plus {
  width: 1.85rem;
  height: 0.23rem;
}

.supportHeader .plus::after {
  transform: rotate(90deg);
}

.supportName {
  width: 50%;
  display: flex;
  align-items: center;
  column-gap: 3.5rem;
}

.supportName span {
  font-style: normal;
  font-weight: 700;
  font-size: 2.8rem;
  line-height: 3.5rem;
  letter-spacing: -0.02em;
  color: #0e192d;
}

.supportHeader > div:last-child {
  width: 12.6rem;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}
.MuiPaper-root-MuiAccordion-root {
  margin: 0 !important;
}
.MuiAccordionSummary-content.Mui-expanded .plus::after {
  transform: rotate(0deg);
}
.MuiAccordionSummary-content.Mui-expanded {
  margin: 0 !important;
}
.MuiAccordionDetails-root {
  padding: 0 !important;
}
.MuiAccordion-root:before {
  display: none;
}
.MuiPaper-root.Mui-expanded,
.MuiAccordion-root.Mui-expanded {
  margin: 0 !important;
}
.MuiPaper-root.Mui-expanded .supportHeader,
.MuiAccordion-root.Mui-expanded .supportHeader {
  border-radius: 0 !important;
}
.supportContent .MuiPaper-root:last-child .supportBody {
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}
.supportBody {
  padding: 4rem 5rem 5.8rem 8.3rem;
  border: 1px solid #e1e3ea;
  border-top: none;
}

.supportBody > div {
  max-height: 70.7rem;
  overflow-y: auto;
  padding-right: 5rem;
}

.supportBody > div::-webkit-scrollbar {
  width: 4px;
  border-radius: 10rem;
}

.supportBody > div::-webkit-scrollbar-track {
  width: 2px;
  background-color: #efefef;
  border-radius: 10rem;
}

.supportBody > div::-webkit-scrollbar-thumb {
  width: 4px;
  background-color: #4ebe96;
  border-radius: 10rem;
}

.supportBody h3 {
  font-style: normal;
  font-weight: 600;
  font-size: 2.4rem;
  line-height: 122%;
  letter-spacing: -0.02em;
  color: #0e192d;
}

.Delivery h3 {
  margin-bottom: 1.7rem;
}

.Delivery p {
  margin-bottom: 2rem;
}

.supportBody p {
  opacity: 0.8;
}
.supportBody ul {
  margin-bottom: 2rem;
}

.FAQ h3 {
  margin-bottom: 1.2rem;
}

.FAQ p {
  margin-bottom: 3.6rem;
}

.FAQ .supportBody > div p:last-child,
.Delivery .supportBody > div p:last-child {
  margin-bottom: 0;
}
.errorPage {
  height: 100vh;
  background-repeat: no-repeat;
  background-position: 35% 50%;
  padding: 4rem 0;
}
.errorPage .container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  height: 100%;
}
.errorPage p {
  font-style: normal;
  font-weight: 500;
  font-size: 2rem;
  line-height: 137%;
  text-align: center;
  letter-spacing: 0.02em;
  color: #0e192d;
}
.errorPage > .container > div:last-child {
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 2rem;
  width: 40%;
}
.errorPage a {
  font-style: normal;
  font-weight: 700;
  font-size: 1.5rem;
  line-height: 1.9rem;
  text-align: center;
  letter-spacing: 0.08em;
  text-transform: uppercase;
  color: #ffffff;
  background: #232323;
  border-radius: 30px;
  height: 5.4rem;
  padding: 0 5rem;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  transition: all 0.3s;
}
.errorPage a:hover {
  background-color: #3f4b47;
}
.errorPage a:active {
  background-color: #000000;
}
.loginPage {
  display: flex;
  height: 100vh;
  overflow: hidden;
}
.loginLeft {
  width: 50%;
  position: relative;
}
.loginLeft a {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: 33%;
}
.reactLoginTabs {
  width: 100%;
}
.loginTabs {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  justify-content: center;
  align-items: flex-end;
  z-index: 3;
}
.loginTabsList {
  position: absolute;
  right: 50%;
  top: 50%;
  transform: translateY(-50%);
}
.loginTabsList li {
  font-style: normal;
  font-weight: 700;
  font-size: 25px;
  line-height: 31px;
  text-align: center;
  height: 8.9rem;
  width: 20.6rem;
  padding: 0 3rem 0 5.5rem;
  /* background-color: #FFF; */
  display: flex;
  justify-content: center;
  align-items: center;
  border-top-left-radius: 10rem;
  border-bottom-left-radius: 10rem;
  transition: all 0.3s;
  z-index: 3;
  position: relative;
  border: none;
  outline: none;
  color: rgba(234, 242, 239, 0.7);
  background-color: transparent;
}
.loginTabsList li:focus {
  outline: none;
  border: none;
}
.loginTabsList li::after,
.loginTabsList li::before {
  display: none;
}

.loginTabsList li.react-tabs__tab--selected {
  background-color: #ffffff;
  color: #000000;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.signUpLink.inactive::after {
  transform: translateY(100%);
}
.signUpLink::after {
  content: "";
  position: absolute;
  right: 0;
  width: 20.6rem;
  height: 8.9rem;
  background-color: #fff;
  border-top-left-radius: 10rem;
  border-bottom-left-radius: 10rem;
  z-index: -1;
  transition: all 0.4s linear;
}
.loginBg {
  height: 100vh;
  width: 100%;
  position: absolute;
  left: 0;
  top: 0;
  z-index: -1;
  background-color: #157a56;
  transition: all 0.4s;
  background-repeat: no-repeat;
  background-size: cover;
}
.loginBg circle {
  transition: all 0.4s;
}
.loginBg svg {
  position: absolute;
}
.loginBg svg:first-child {
  top: 0;
  left: 0;
}
.loginBg svg:nth-child(2) {
  top: 0;
  right: 2.5rem;
}
.loginBg svg:nth-child(3) {
  top: 20%;
  left: 20%;
}
.loginBg svg:nth-child(4) {
  bottom: 0;
  left: 0;
  width: 30rem;
  height: 30rem;
}
.loginBg svg:nth-child(5) {
  bottom: 4rem;
  right: 5rem;
}
.loginBg.active {
  background-color: #bd1a1a;
}
.loginBg.active2 {
  background-color: #167d58;
}
.loginBg.active svg:first-child circle:nth-child(2),
.loginBg.active svg:nth-child(2) circle:nth-child(2),
.loginBg.active svg:nth-child(3) circle:nth-child(2),
.loginBg.active svg:nth-child(4) circle:nth-child(2),
.loginBg.active svg:nth-child(5) circle:nth-child(2) {
  fill: #a61717;
}
.loginBg.active svg:first-child circle:first-child,
.loginBg.active svg:nth-child(2) circle:first-child,
.loginBg.active svg:nth-child(3) circle:first-child,
.loginBg.active svg:nth-child(4) circle:first-child,
.loginBg.active svg:nth-child(5) circle:first-child {
  fill: #8b1313;
}
.loginRight {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 50%;
}
.loginContainer {
  width: 100%;
}
.loginContainer form {
  width: 50%;
  margin: 0 auto;
}
.loginRight h2,
.forgotPass h2 {
  font-style: normal;
  font-weight: 700;
  font-size: 3.6rem;
  line-height: 4.5rem;
  text-align: center;
  color: #000000;
  margin-bottom: 3.7rem;
}
.loginContainer form label,
.forgotPass form label,
.profileRight form label,
.addNewAddr form label,
.basketRight label,
.basketRegister label {
  font-style: normal;
  font-weight: 400;
  font-size: 1.2rem;
  line-height: 1.5rem;
  letter-spacing: -0.02em;
  color: #000000;
  opacity: 0.48;
  margin-bottom: 0.5rem;
  display: block;
  padding-left: 1rem;
}
.loginContainer form input,
.forgotPass form input,
.profileRight form input,
.addNewAddr form input,
.basketRight input,
.basketRegister form input {
  background: #f4f7f7;
  border-radius: 8px;
  height: 4.8rem;
  width: 100%;
  border: 1px solid transparent;
  padding: 0 2.4rem;
  font-style: normal;
  font-weight: 500;
  font-size: 1.4rem;
  line-height: 1.8rem;
  letter-spacing: -0.02em;
  color: #000000;
  margin-bottom: 1.6rem;
  transition: all 0.3s;
}
.loginContainer form input::placeholder,
.forgotPass form input::placeholder,
.profileRight form input::placeholder,
.addNewAddr form input::placeholder,
.basketRight input::placeholder,
.basketRegister input::placeholder {
  color: rgba(0, 0, 0, 0.5);
}
.loginContainer form input:focus,
.forgotPass form input:focus,
.profileRight form input:focus,
.addNewAddr form input:focus,
.basketRight input:focus,
.basketRegister input:focus {
  color: #0d8359;
}
.loginContainer .contactPhone input:focus + input,
.forgotPass form .contactPhone input:focus + input,
.profileRight form .contactPhone input:focus + input,
.addNewAddr form .contactPhone input:focus + input,
.basketRight .contactPhone input:focus + input {
  color: #0d8359;
}
.loginContainer .contactPhone input:first-child,
.forgotPass form .contactPhone input:first-child,
.profileRight form .contactPhone input:first-child,
.addNewAddr form .contactPhone input:first-child,
.basketRight .contactPhone input:first-child {
  width: 75%;
}
.loginContainer .contactPhone input:last-child,
.forgotPass form .contactPhone input:last-child,
.profileRight form .contactPhone input:last-child,
.addNewAddr form .contactPhone input:last-child,
.basketRight .contactPhone input:last-child {
  width: 25%;
  padding-right: 0;
}
#signUpTab form button,
#loginTab form button,
.forgotPass form button,
.profileRight form > button,
.addNewAddr form button,
.alert-body button {
  width: 100%;
  height: 5.2rem;
  background: #0d8359;
  border-radius: 8px;
  font-style: normal;
  font-weight: 700;
  font-size: 1.6rem;
  line-height: 2rem;
  text-align: center;
  letter-spacing: 0.04em;
  text-transform: uppercase;
  color: #ffffff;
  border: none;
  transition: all 0.3s;
}
#signUpTab form button:hover,
.profileRight form button:hover,
.addNewAddr form button:hover,
.alert-body button:hover {
  background-color: #0a6142;
}
#signUpTab form button:active,
.profileRight form button:active,
.addNewAddr form button:active,
.alert-body button:active {
  background-color: #063826;
}
#loginTab form button,
.forgotPass form button {
  background-color: #1e2623;
  margin-bottom: 1.6rem;
}
#loginTab form button:hover,
.forgotPass form button:hover {
  background-color: #3f4b47;
}
#loginTab form button:active,
.forgotPass form button:active {
  background-color: #000000;
}
#loginTab form button + div {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
#loginTab form button + div a {
  font-style: normal;
  font-weight: 500;
  font-size: 1.2rem;
  line-height: 1.5rem;
  letter-spacing: -0.02em;
  color: #121212;
  opacity: 0.75;
  padding-bottom: 0.5rem;
  border-bottom: 1px solid #121212;
  transition: all 0.3s;
}
#loginTab form button + div a:hover {
  opacity: 1;
  color: #0d8359;
}
.forgotPass {
  width: 50vw;
  height: 100vh;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 5;
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.5s linear;
  transform: translateX(100%);
}
.forgotPass.active {
  transform: translateX(0);
}
.forgotPass h2 {
  margin-bottom: 0.5rem;
}
.forgotPass > div > div {
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 0.9rem;
  margin-bottom: 3.2rem;
}
.back {
  border: none;
  padding: 0;
  background-color: transparent;
}
.forgotPass form button {
  margin-bottom: 2rem;
}
.forgotPass p {
  font-style: normal;
  font-weight: 400;
  font-size: 1.5rem;
  line-height: 1.9rem;
  text-align: center;
  letter-spacing: 0.02em;
  color: #2d2c2d;
  opacity: 0.4;
}
.profilePage {
  padding-top: 2rem;
}
.profilePage h2 {
  font-style: normal;
  font-weight: 700;
  font-size: 3rem;
  line-height: 3.8rem;
  letter-spacing: -0.01em;
  font-feature-settings: "liga" off;
  color: #000000;
  margin-bottom: 0.8rem;
}
.profileContent {
  border-top: 1px solid #f2f0f0;
}
.profileContent > .container {
  display: flex;
}
.profileLeft {
  width: 20%;
  border-right: 1px solid #f2f0f0;
  padding-top: 4.1rem;
}
.profileLeft ul {
  display: flex;
  flex-direction: column;
  row-gap: 1.8rem;
}
.profileLeft ul li a {
  font-style: normal;
  font-weight: 500;
  font-size: 1.5rem;
  line-height: 1.9rem;
  letter-spacing: -0.02em;
  font-feature-settings: "pnum" on, "lnum" on, "kern" off;
  color: #1f1414;
  opacity: 0.4;
  transition: all 0.3s;
  position: relative;
}
.profileLeft ul li a:hover {
  opacity: 1;
  color: #0d8359;
}
.profileLeft ul li a.active {
  font-weight: 700;
  font-size: 19px;
  line-height: 24px;
  color: #0d8359;
  opacity: 1;
  padding-left: 2.2rem;
  pointer-events: none;
}
.profileLeft ul li a.active::before {
  content: "";
  position: absolute;
  width: 0.8rem;
  height: 0.8rem;
  border-radius: 50%;
  background-color: #0d8359;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
}
.profileRight {
  padding-top: 5.4rem;
  width: 80%;
  padding-bottom: 7.1rem;
}
.profileDetails {
  display: flex;
}
.profileDetails > div:first-child {
  width: 45%;
  padding: 0 5.6rem 0 7.8rem;
  position: relative;
}
.profileDetails > div:first-child::after {
  content: "";
  position: absolute;
  width: 1px;
  height: 30.4rem;
  background-color: #f2f0f0;
  right: 0;
  top: 0;
}
.profileDetails > div:last-child {
  width: 55%;
  padding-left: 4.7rem;
}
.profileRight h5 {
  font-style: normal;
  font-weight: 600;
  font-size: 2.4rem;
  line-height: 3rem;
  letter-spacing: -0.02em;
  font-feature-settings: "pnum" on, "lnum" on, "kern" off;
  color: #121212;
}
.profileRight h5 + form {
  margin-top: 2.9rem;
}
.profileRight form > button {
  width: 50%;
  font-size: 1.4rem;
  height: 4.8rem;
}
.addressDetails > div:first-child {
  display: flex;
  justify-content: space-between;
  margin-bottom: 2rem;
}
.profileRight .addressDetails {
  width: 55%;
  padding-left: 5rem;
}
.addNewAddress {
  height: 4.1rem;
  border: 1px solid #eceeee;
  border-radius: 4.3px;
  background-color: #0d8359;
  font-style: normal;
  font-weight: 600;
  font-size: 1.5rem;
  line-height: 97.1%;
  letter-spacing: -0.03em;
  font-feature-settings: "liga" off;
  color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 0.5rem;
  padding: 0 2rem;
  transition: all 0.3s;
}
.addNewAddress path {
  fill: #ffffff;
}
.addNewAddress:hover {
  color: #ffffff;
  background-color: #0a6142;
}
.addNewAddress:active {
  color: #ffffff;
}
.addNewAddr .css-13cymwt-control {
  width: 100% !important;
  height: 4.8rem;
  margin-bottom: 1.6rem;
  border-radius: 8px !important;
}
.addNewAddr .css-t3ipsp-control {
  width: 100% !important;
  height: 4.8rem;
  margin-bottom: 1.6rem;
  border-radius: 8px !important;
}
.addNewAddr .css-1xc3v61-indicatorContainer {
  transform: translateY(-0.5rem) !important;
}
.addNewAddr .css-15lsz6c-indicatorContainer {
  transform: translateY(-0.5rem) !important;
}
.errorOtpButton,
.successOtpButton,
.logout,
.successOrder {
  height: 5.2rem;
  background: #eb001e;
  border-radius: 8px;
  width: 100%;
  font-weight: 700;
  font-size: 1.6rem;
  line-height: 2rem;
  text-align: center;
  letter-spacing: 0.04em;
  text-transform: uppercase;
  color: #ffffff;
  transition: all 0.4s linear;
  border: none;
}
.successOtpButton,
.successOrder {
  background-color: rgba(13, 131, 89, 1);
}
.errorOtpButton:hover,
.logout:hover {
  background-color: rgba(214, 0, 27, 1);
}
.errorOtpButton:active,
.logout:active {
  background-color: rgba(158, 0, 20, 1);
}
.successOtpButton:hover,
.successOrder:hover {
  background-color: rgba(10, 97, 66, 1);
}
.successOtpButton:active,
.successOrder:active {
  background-color: rgba(6, 56, 38, 1);
}
.addNewAddr p,
.addNewAddr h1,
.orderMsg {
  font-weight: 500;
  font-size: 1.8rem;
  line-height: 1.8rem;
  letter-spacing: -0.02em;
  color: #000000;
  opacity: 0.5;
  text-align: center;
  margin-bottom: 5.2rem;
}

.addresses form,
.basketRight .addresses > div {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  row-gap: 2rem;
}
.addresses form input,
.addresses input {
  display: none;
}
.addresses .radioGroup {
  width: 45%;
}
.basketRight .addresses .radioGroup {
  width: 48%;
}
.basketLink {
  position: relative;
}
.basketLink span {
  position: absolute;
  font-weight: 500;
  font-size: 1.3rem;
  color: #ffffff;
  top: -1.5rem;
  left: 100%;
  transform: translateX(-50%);
  width: 2rem;
  height: 2rem;
  border-radius: 50%;
  background-color: #13996a;
  display: flex;
  justify-content: center;
  align-items: center;
}
.menu .basketLink span {
  right: 0.5rem;
  left: unset;
  top: 0.5rem;
}
.registerButton {
  width: 45%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 6rem;
}
.registerButton .seeAll {
  font-size: 2.8rem;
  column-gap: 3rem;
  padding: 1.4rem 4rem;
  color: #ffffff;
}
.registerButton .seeAll svg {
  scale: 1.5;
  margin-bottom: -2px;
}
.addresses .radioGroup span {
  font-style: normal;
  font-weight: 500;
  font-size: 0.9rem;
  line-height: 122.1%;
  letter-spacing: -0.02em;
  font-feature-settings: "liga" off;
  color: #303030;
  opacity: 0.5;
  display: block;
}
.addresses .radioGroup p {
  font-style: normal;
  font-weight: 400;
  font-size: 1.46rem;
  line-height: 122.1%;
  letter-spacing: -0.02em;
  font-feature-settings: "liga" off;
  color: #303030;
}
.addresses .radioGroup label {
  display: flex;
  flex-direction: column;
  row-gap: 1.3rem;
  opacity: 1;
  padding: 1.9rem 1.6rem 4rem;
  border: 1px solid #d2d2e4;
  border-radius: 7.8px;
  transition: all 0.3s;
  position: relative;
}
.addresses input:checked + label {
  border-color: #15bd74;
}
.addresses input:checked + label .check {
  opacity: 1;
}

.deleteAddr,
.editAddr {
  height: auto;
  width: unset;
  padding: 0;
  font-style: normal;
  font-weight: 400;
  font-size: 1.06rem;
  line-height: 1.3rem;
  letter-spacing: -0.02em;
  color: #151515;
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 0.3rem;
  background-color: transparent;
  text-transform: lowercase;
  position: absolute;
  bottom: 1.2rem;
  right: 1.3rem;
  border: none;
}
.editAddr {
  right: unset;
  left: 1.5rem;
}
.addresses .radioGroup button:hover {
  background-color: transparent;
}
.addresses .radioGroup button:active {
  background-color: transparent;
}
.check {
  width: 2.7rem;
  height: 2.7rem;
  border-radius: 50%;
  background-color: #0d8359;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 1rem;
  right: 1.2rem;
  opacity: 0;
  transition: all 0.3s;
}
.addNewAddr {
  width: 58.2rem;
  background-color: #fff;
  border-radius: 15px;
  z-index: 10;
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -80%);
  padding: 5.4rem 10rem 7rem;
  display: block;
  visibility: hidden;
  opacity: 0;
  transition: all 0.5s;
}
.registerNow {
  color: #000;
  font-size: 1.6rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: -0.048rem;
}
.registerNow svg {
  width: 3rem;
  height: 1rem;
  margin-left: 1rem;
}
.addNewAddr.active,
.basketRegister .addNewAddr {
  opacity: 1;
  visibility: visible;
  transform: translate(-50%, -50%);
}
.basketRegister .addNewAddr {
  width: 100%;
  position: static;
  transform: translate(0);
  padding: 0;
}
.modalBg {
  position: fixed;
  width: 100vw;
  height: 100vh;
  left: 0;
  top: 0;
  background: #878787;
  mix-blend-mode: multiply;
  z-index: 9;
  display: block;
  opacity: 0;
  visibility: hidden;
  transition: all 0.5s;
}
.addNewAddr.active + .modalBg {
  visibility: visible;
  opacity: 1;
}
.modalClose {
  padding: 0;
  background-color: transparent;
  border: none;
  position: absolute;
  right: 3.7rem;
  top: 3.7rem;
}
.addNewAddr h4 {
  font-style: normal !important;
  font-weight: 700 !important;
  font-size: 2.8rem !important;
  line-height: 3.5rem !important;
  text-align: center !important;
  color: #000000 !important;
  margin-bottom: 2.3rem !important;
}
.addNewAddr .select {
  height: 4.8rem;
  width: 100%;
  margin-bottom: 1.2rem;
}
.addNewAddr .select-styled {
  background-color: #f4f7f7;
  border-radius: 8px;
  padding: 0 2.4rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-style: normal;
  font-weight: 500;
  font-size: 1.4rem;
  line-height: 1.8rem;
  letter-spacing: -0.02em;
  color: rgba(0, 0, 0, 0.5);
  height: 100%;
}
.favoritesPage {
  padding-left: 5rem;
}
.favoritesPage h5 {
  padding-left: 3rem;
}
.favoritesPage > div:first-child {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2.75rem;
  padding-right: 1rem;
}
.forgetPass {
  display: flex;
  justify-content: flex-end;
}
.forgetPass a {
  font-weight: 500;
  font-size: 1.6rem;
  cursor: pointer;
  padding-bottom: 0.2rem;
  border-bottom: 1px solid rgba(80, 80, 80, 1);
  color: rgba(80, 80, 80, 1);
}
.empty {
  padding-top: 7.8rem;
  padding-bottom: 16rem;
  display: flex;
  justify-content: center;
  align-items: center;
  row-gap: 1.5rem;
  flex-direction: column;
  width: 100%;
}
.empty p {
  font-style: normal;
  font-weight: 600;
  font-size: 1.8rem;
  line-height: 1.6rem;
  letter-spacing: 0.01em;
  font-feature-settings: "pnum" on, "lnum" on, "kern" off;
  color: #121212;
  opacity: 0.4;
}
.addProduct {
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 0.64rem;
  font-style: normal;
  font-weight: 600;
  font-size: 1.92rem;
  line-height: 97.1%;
  letter-spacing: -0.03em;
  font-feature-settings: "liga" off;
  color: #363636;
  height: 5.25rem;
  padding: 0 3rem;
  border: 1px solid #eceeee;
  border-radius: 5.4px;
  transition: all 0.3s;
}
.addProduct:hover {
  color: #0d8359;
}
.addProduct:active {
  color: #363636;
}
.favoritesPage.emptyPage > div:first-child .addProduct {
  display: none;
}
.favorites {
  display: flex;
  flex-wrap: wrap;
  row-gap: 9.3rem;
}
.favorite {
  width: calc(100% / 3);
  padding: 0 1rem;
}
.favorite .product {
  height: auto;
}
.removeFav {
  font-style: normal;
  font-weight: 500;
  font-size: 1.56rem;
  line-height: 2rem;
  letter-spacing: -0.02em;
  font-feature-settings: "pnum" on, "lnum" on, "liga" off, "kern" off;
  color: #000000;
  background-color: transparent;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 0.9rem;
  margin-top: 1.4rem;
}

.ordersContent > div:first-child {
  display: flex;
  padding: 3.6rem 0 2.4rem 5.2rem;
  border: 1px solid #f2f0f0;
  border-bottom: none;
  border-top-left-radius: 0.8rem;
  border-top-right-radius: 0.8rem;
}

.ordersContent > div:first-child span {
  font-style: normal;
  font-weight: 600;
  font-size: 1.6rem;
  line-height: 116.1%;
  letter-spacing: -0.02em;
  font-feature-settings: "liga" off;
  color: #282828;
  width: 15%;
}
.ordersContent > div:first-child span:nth-child(6) {
  width: 10%;
}

.orders {
  border: 1px solid #e4e4eb;
  border-bottom: none;
}

.orders:last-child {
  border-bottom: 1px solid #e4e4eb;
  border-bottom-left-radius: 1.6rem;
  border-bottom-right-radius: 1.6rem;
}

.orders > div:first-child {
  padding: 2.93rem 0 2.91rem 5.2rem;
  display: flex;
  align-items: center;
  pointer-events: none;
}

.orders > div > div {
  width: 15%;
}
.orders > div > div:nth-child(6) {
  width: 10%;
}
.orders > div > div:last-child {
  width: 15%;
}

.orders > div > div:last-child button {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.97rem;
  pointer-events: all;
  background-color: transparent;
  border: none;
}

.orders > div > div:last-child button span {
  font-style: normal;
  font-weight: 500;
  font-size: 1.4rem;
  line-height: 116.1%;
  letter-spacing: -0.02em;
  font-feature-settings: "liga" off;
  color: #131313;
}

.orders > div > div:last-child button span:last-child,
.orders > div > div:last-child button span:nth-child(2) {
  display: inline-block;
  width: 8rem;
}
.orders > div > div:last-child button span:last-child {
  display: none;
}
.toggle.active > div:last-child button span:nth-child(2) {
  display: none;
}
.toggle.active > div:last-child button span:last-child {
  display: inline-block;
}
.toggle span {
  font-style: normal;
  font-weight: 500;
  font-size: 1.4rem;
  line-height: 116.1%;
  letter-spacing: -0.02em;
  font-feature-settings: "liga" off;
  color: #565656;
}

.orders > div:first-child > div:first-child span:first-child {
  display: inline-block;
  width: 10.3rem;
  height: 3rem;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #170e0f;
  border-radius: 0.68rem;
  font-style: normal;
  font-weight: 500;
  font-size: 1.25rem;
  line-height: 116.1%;
  text-align: center;
  letter-spacing: -0.02em;
  font-feature-settings: "liga" off;
  color: #ffffff;
}
.orders > div:first-child > div:first-child span:last-child {
  display: none;
}
.paid {
  background-color: #0d8359 !important;
  color: #fff !important;
}
.ordersContent .plus {
  position: relative;
  width: 1rem;
  height: 1rem;
}

.ordersContent .plus::after,
.ordersContent .plus::before {
  content: "";
  position: absolute;
  top: 0;
  border: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  content: "";
  background-color: #174a3b;
  opacity: 0.8;
  width: 0.1rem;
  height: 0.83rem;
  border-radius: 10rem;
  transition: 0.3s;
}

.ordersContent .plus::before {
  transform: rotate(90deg);
}

.toggle.active .plus::after {
  transform: rotate(-90deg);
}

.orderDetails {
  /*display: none;*/
  padding: 0 2.4rem 3rem;
}

.toggle.active .seeDetails::before {
  display: inline-block;
}

.orderDetails > div:last-child {
  display: flex;
  flex-wrap: wrap;
  width: 100% !important;
  overflow-y: auto;
  overflow-x: hidden;
  max-height: 36rem;
  row-gap: 1.6rem;
  column-gap: 2rem;
  align-items: flex-start;
}
.orderDetails > div:last-child::-webkit-scrollbar {
  width: 1px;
  background-color: #cacaca;
  border-radius: 10rem;
}
.orderDetails > div:last-child::-webkit-scrollbar-track {
  width: 1px;
  background-color: #0d8359;
  border-radius: 10rem;
}
.orderDetails > div:first-child {
  display: none;
}

.orderImg img {
  height: 95%;
}

.order {
  width: 46%;
  display: flex;
  align-items: flex-start;
  border: 1px solid #ece9e9;
  border-radius: 8px;
  padding: 1.3rem 2.2rem 1.3rem 1.3rem;
}

.orderBody > div:first-child {
  display: flex;
  justify-content: space-between;
}

.orderBody > div:last-child {
  display: flex;
  flex-direction: column;
  gap: 0.127rem;
}

.orderPrice {
  font-style: normal;
  font-weight: 600;
  font-size: 2.48rem;
  line-height: 116.1%;
  letter-spacing: -0.03em;
  font-feature-settings: "liga" off;
  color: #1c1c1c;
}
.orderPrice svg {
  margin-left: 0.4rem;
}
.orderCount {
  font-style: normal;
  font-weight: 500;
  font-size: 1.6rem;
  line-height: 116.1%;
  letter-spacing: -0.02em;
  font-feature-settings: "liga" off;
  color: #1c1c1c;
}
.orderName {
  font-style: normal;
  font-weight: 500;
  font-size: 1.3rem;
  line-height: 130.1%;
  letter-spacing: -0.02em;
  font-feature-settings: "liga" off;
  color: #1c1c1c;
  opacity: 0.4;
  width: 95%;
}
.orderImg {
  border: 1px solid #f2f0f0;
  border-radius: 8px;
  width: 10rem;
  height: 10rem;
  min-width: 10rem;
  min-height: 10rem;
  display: flex;
  justify-content: center;
  align-items: center;
}
.orderBody {
  padding: 1rem 0 1rem 1.8rem;
  display: flex;
  flex-direction: column;
  row-gap: 1.5rem;
  width: 100%;
}
.basketItems .orderBody {
  width: 100%;
}
.changePassword {
  padding-left: 7.8rem;
}
.changePassword form {
  width: 40%;
}
.blogText p,
.supportBody p,
.supportBody li {
  width: 100%;
  font-size: 1.5rem;
  line-height: 1.9rem;
}

.basketPage {
  padding-top: 3rem;
}
.basketTop > .container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 1rem;
  border-bottom: 1px solid #f2f0f0;
  padding-right: 9rem;
}
.basketContent > .container > .form {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
}
.basketPage h1 {
  font-style: normal;
  font-weight: 700;
  font-size: 3rem;
  line-height: 3.8rem;
  letter-spacing: -0.01em;
  font-feature-settings: "liga" off;
  color: #000000;
}
.basketLeft {
  padding-top: 4rem;
  padding-right: 6.5rem;
  padding-bottom: 8rem;
  width: 55%;
  border-right: 1px solid #f2f0f0;
  position: sticky;
  top: 2rem;
}
.basketRegister {
  padding: 4rem 6.5rem 8rem;
}
.basketPage h4,
.basketPage h5,
.basketRegister h4 {
  font-style: normal;
  font-weight: 600;
  font-size: 1.7rem;
  line-height: 2.1rem;
  letter-spacing: -0.01em;
  font-feature-settings: "liga" off;
  color: #000000;
}
.basketItems .order {
  width: 90%;
  position: relative;
}
.basketItems {
  margin-top: 1.3rem;
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
  max-height: 55rem;
  overflow: auto;
  margin-bottom: 2.8rem;
}
.basketItems::-webkit-scrollbar {
  width: 1px;
  background-color: #ece9e9;
  border-radius: 10rem;
}
.basketItems::-webkit-scrollbar-thumb {
  background-color: #0d8359;
  border-radius: 10rem;
}
.basketItems .orderBody > div:last-child {
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.basketItems .orderName {
  width: 60%;
}
.removeItem {
  background-color: transparent;
  border: none;
  padding: 0;
  position: absolute;
  top: 2.3rem;
  right: 2.6rem;
}
.total {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-right: 7rem;
}
.total button {
  font-style: normal;
  font-weight: 700;
  font-size: 1.6rem;
  line-height: 2rem;
  text-align: center;
  letter-spacing: 0.04em;
  text-transform: uppercase;
  color: #ffffff;
  background: #0d8359;
  border-radius: 8px;
  height: 5.2rem;
  border: none;
  padding: 0 3rem;
  transition: all 0.3s;
}
.total button:hover {
  background-color: #0a6142;
}
.total button:active {
  background-color: #063826;
}
.total > div:last-child {
  display: flex;
  flex-direction: column;
}
.totalPrice {
  margin-bottom: 1.4rem;
  display: flex;
  align-items: center;
  column-gap: 2.6rem;
}
.totalPrice span:first-child {
  font-style: normal;
  font-weight: 400;
  font-size: 2.4rem;
  line-height: 101.8%;
  letter-spacing: -0.02em;
  color: #010302;
  opacity: 0.8;
}
.totalPrice span:last-child {
  font-style: normal;
  font-weight: 600;
  font-size: 3.6rem;
  line-height: 156.8%;
  text-align: right;
  letter-spacing: -0.02em;
  color: #222d2a;
}
.total > div:last-child > span {
  font-style: normal;
  font-weight: 500;
  font-size: 1.2rem;
  line-height: 101.8%;
  letter-spacing: -0.02em;
  color: #283531;
  margin-bottom: 0.4rem;
}
.basketRight {
  width: 45%;
  padding-top: 6rem;
  padding-left: 8.5rem;
  padding-right: 9rem;
  padding-bottom: 7.2rem;
}
.shipping h4 {
  margin-bottom: 1.3rem;
}
.shipping textarea {
  width: 100%;
  background: #f4f7f7;
  border-radius: 8px;
  resize: none;
  border: none;
  padding: 1.4rem 2.4rem;
  height: 14.3rem;
}
.shipping textarea::placeholder {
  color: rgba(0, 0, 0, 0.5);
}
/* .shipping textarea:focus {
  color: #0D8359;
} */
.shipping {
  margin-bottom: 4.8rem;
}
.paymentType h4 {
  margin-bottom: 1.3rem;
}
.paymentType .radioGroup input {
  display: none;
}
.paymentType .radioGroup label span:first-child {
  width: 1.9rem;
  height: 1.9rem;
  border-radius: 50%;
  border: 1px solid #4e4e4e;
  display: inline-block;
  position: relative;
  transition: all 0.3s;
}
.paymentType .radioGroup label span:last-child {
  font-style: normal;
  font-weight: 400;
  font-size: 1.7rem;
  line-height: 2.1rem;
  letter-spacing: -0.03em;
  font-feature-settings: "pnum" on, "lnum" on, "liga" off, "kern" off;
  color: #414141;
}
.paymentType .radioGroup label span:first-child::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background-color: #0d8359;
  top: 0;
  left: 0;
  opacity: 0;
  transition: all 0.3s;
  scale: 0.7;
}
.paymentType .radioGroup {
  margin-bottom: 1.1rem;
}
.paymentType .radioGroup label {
  padding: 0;
  margin: 0;
  display: flex;
  column-gap: 1.1rem;
  align-items: center;
  opacity: 1;
}
.paymentType .radioGroup input:checked + label span:last-child {
  color: #0d8359;
}
.paymentType .radioGroup input:checked + label span:first-child::before {
  opacity: 1;
}

.number-input-container {
  width: auto;
  display: grid;
  grid-template-columns: 3.5rem 3.5rem 3.5rem;
  border: 1.19101px solid #000000;
  border-radius: 4.76405px;
  height: 3.7rem;
  margin-left: auto;
}

.number-input {
  width: 100%;

  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;

  background-color: var(--color-mustard-extra-light);

  overflow: hidden;
}

.number-input-container button {
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  background-color: transparent;
  padding: 0;
  position: relative;
}
input[type="number"] {
  -webkit-appearance: none;
  -webkit-border-radius: 0px;
  -moz-appearance: none;
  appearance: none;

  position: relative;

  width: 100%;
  min-width: 100%;
  height: 48px;

  background-color: var(--color-mustard-extra-light);

  border: 1px solid var(--color-mustard-light);
  border-left: 1px solid rgba(0, 0, 0, 0);
  border-right: 1px solid rgba(0, 0, 0, 0);
  border-radius: none;

  font-size: 16px;
  line-height: 24px;
  text-align: center;

  transition: all 0.2s ease-out;
}

input[type="number"]:focus {
  background-color: white;

  border: 1px solid var(--color-mustard-midtone);

  outline: none;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
  appearance: textfield;
}

button:active,
button:focus {
  outline: none;
}

button:disabled {
  pointer-events: none;
}

.button-increment::before {
  content: "";
  position: absolute;
  width: 0.914rem;
  height: 1px;
  background-color: #1d1b1b;
  border-radius: 10rem;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.button-increment::after {
  content: "";
  position: absolute;
  height: 0.914rem;
  width: 1px;
  background-color: #1d1b1b;
  border-radius: 10rem;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.button-decrement::before {
  content: "";
  position: absolute;
  width: 0.914rem;
  height: 1px;
  background-color: #1d1b1b;
  border-radius: 10rem;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.basketRight > h4:first-child,
.basketRegister > h4:first-child,
.basketRegister h5 {
  margin-bottom: 2.4rem;
}
.basketRight .addressDetails {
  margin-top: 3rem;
  margin-bottom: 4.8rem;
}
.basketRight .addressDetails > div:first-child {
  align-items: center;
}
.categoryMenu > li > a svg,
.mobileMenuTop,
.subMenuClose,
.menu {
  display: none;
}
.backBtn,
.closeFilter,
.filterBtn {
  display: none !important;
}
.logo img {
  width: 11.1rem;
}
.css-13cymwt-control,
.css-t3ipsp-control {
  width: 23.4rem !important;
  height: 6rem;
  background-color: rgba(244, 247, 247, 1) !important;
  border-radius: 5px;
  border: none !important;
  box-shadow: none !important;
}
.css-t3ipsp-control:hover {
  border: none !important;
  border-color: transparent !important;
}
.css-1nmdiq5-menu {
  background-color: rgba(244, 247, 247, 1) !important;
  border-radius: 5px !important;
  box-shadow: none !important;
  padding: 1.3rem 1.7rem 1.9rem 2.2rem;
}
.css-1nmdiq5-menu > div {
  max-height: 19.8rem;
  overflow: auto;
}
.css-1nmdiq5-menu > div::-webkit-scrollbar {
  width: 2px;
  background-color: transparent;
}
.css-1nmdiq5-menu > div::-webkit-scrollbar-thumb {
  width: 2px;
  background: #0d8359;
  border-radius: 100px;
}
.css-1fdsijx-ValueContainer {
  padding-left: 2.5rem !important;
}
.css-1dimb5e-singleValue {
  font-weight: 500;
  font-size: 1.6rem;
  line-height: 2rem;
  letter-spacing: -0.02em;
  color: #000000;
}
.css-1jqq78o-placeholder,
.css-1u9des2-indicatorSeparator,
header .logo + .menuTop,
.loginRight .logo {
  display: none;
}
.orderSuccess {
  padding-top: 10rem;
  padding-bottom: 11.25rem;
}
.orderSuccess .container span {
  display: flex;
  column-gap: 1.8rem;
  align-items: center;
  justify-content: center;
  font-weight: 700;
  font-size: 3rem;
  line-height: 3.8rem;
  text-align: center;
  letter-spacing: -0.01em;
  font-feature-settings: "liga" off;
  color: #000000;
  padding-bottom: 1.75rem;
  margin-bottom: 2.5rem;
  position: relative;
}
.orderSuccess span::after {
  content: "";
  position: absolute;
  width: 100vw;
  margin-left: -50vw;
  margin-right: -50vw !important;
  left: 50%;
  right: 50%;
  height: 1px;
  background-color: rgba(242, 240, 240, 1);
  bottom: 0;
}
.orderSuccess span div {
  width: 4rem;
}
.orderSuccess span div img {
  width: 100%;
}
.orderSuccess p {
  font-weight: 600;
  font-size: 1.6rem;
  line-height: 2.2rem;
  text-align: center;
  letter-spacing: -0.02em;
  font-feature-settings: "pnum" on, "lnum" on, "kern" off;
  color: #121212;
  opacity: 0.7;
  margin: 0 auto 6.8rem;
  width: 40%;
}
.orderSuccess a {
  margin: 0 auto;
  width: fit-content;
  border: 1px solid #eceeee;
  border-radius: 0.54rem;
  height: 5.25rem;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 5.2rem;
  font-weight: 600;
  font-size: 1.92rem;
  line-height: 97.1%;
  text-align: center;
  letter-spacing: -0.03em;
  font-feature-settings: "liga" off;
  color: #363636;
  transition: all 0.3s linear;
}
.orderSuccess a:hover {
  border-color: rgba(13, 131, 89, 0.5);
}
.call {
  display: none;
}
@media screen and (max-width: 768px) {
  .campaignAds .imgContainer {
    height: auto;
  }
  .call {
    display: flex;
    height: 5rem;
    position: fixed;
    bottom: 2rem;
    right: 2rem;
    background-color: #ff2f49;
    z-index: 10;
    border-radius: 0.8rem;
  }
  .call a {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-inline: 2.4rem;
  }
  .call a:first-child {
    border-right: 2px solid #f32d44;
    display: flex;
    align-items: center;
    column-gap: 0.8rem;
    font-size: 1.6rem;
    color: #ffffff;
    font-weight: 500;
  }
  .errorPage {
    background-size: contain;
  }
  .errorPage > .container > div:last-child {
    width: 100%;
  }
  .errorPage > .container > div:last-child > div {
    width: 85%;
  }
  .errorPage a {
    width: 100%;
    padding: 0 2rem;
    display: flex;
  }
  .allProducts > .container > .campaignAds {
    display: block;
  }
  .campaignAds,
  .loginLeft {
    display: none;
  }
  .loginRight {
    width: 100%;
  }
  .loginContainer form {
    width: 90%;
  }
  .loginTabsList {
    position: fixed;
    right: unset;
    top: unset;
    left: 50%;
    bottom: 3rem;
    transform: translate(-50%, 0);
    display: flex;
    column-gap: 2rem;
  }
  .loginTabsList li {
    font-size: 1.8rem;
    width: unset;
    height: unset;
    padding: 1rem 2rem;
    white-space: nowrap;
    color: #157a56;
    border-radius: 0.8rem !important;
  }
  .loginTabsList li.react-tabs__tab--selected {
    color: #ffffff;
    background-color: #0d8359;
  }
  .forgotPass {
    width: 100%;
  }
  .loginRight h2,
  .forgotPass h2 {
    font-size: 2.4rem;
    margin-bottom: 1.6rem;
  }
  .forgotPass h2 {
    margin-bottom: 0;
  }
  .forgotPass form {
    width: 90%;
    margin: 0 auto;
  }
  .loginRight .logo {
    position: absolute;
    top: 4.6rem;
    left: 50%;
    transform: translateX(-50%);
    display: block;
  }
  .empty-products {
    width: 100%;
  }
  header > div:nth-child(2).active {
    position: fixed;
    top: 0;
    width: 100%;
    background-color: #ffffff;
    z-index: 15;
  }
  header .logo + .menuTop {
    display: flex;
    justify-content: center;
    column-gap: 2.6rem;
    border: none;
  }
  header .logo + .menuTop > a {
    border: none;
    width: auto;
  }
  header .logo + .menuTop .basketLink span {
    top: 0;
  }
  .order {
    padding: 0.8rem !important;
  }
  .orderImg {
    height: 15rem !important;
    min-height: unset;
    min-width: unset;
    width: 50%;
  }
  .basketItems .orderImg img {
    max-width: 70%;
    height: 80%;
    object-fit: contain;
  }
  .orderBody {
    width: 50%;
  }
  .menu {
    position: fixed;
    left: 0;
    top: 8.8rem;
    display: block;
    height: calc(100vh - 8.8rem);
    width: 100vw;
    background-color: #ffffff;
    z-index: 20;
    transform: translateX(-100%);
    transition: all 0.4s linear;
    padding-bottom: 12rem;
    padding-top: 1.6rem;
    overflow-y: auto;
    overflow-x: hidden;
  }
  .menuBack {
    position: absolute;
    padding: 0 0 0 2.6rem;
    top: 9rem;
    left: 0;
    background-color: transparent;
    border: none;
    z-index: 26;
    transition: all 0.4s linear;
    transform: translateX(-10rem);
  }

  .menu.active {
    transform: translateX(0);
  }
  .menuTop {
    display: flex;
    width: 100%;
    border: 1px solid #e2e7e6;
    border-radius: 9.3px;
  }
  .menuTop > a {
    width: calc(65% / 3);
    height: 4.8rem;
    display: flex;
    justify-content: center;
    align-items: center;
    border-right: 1px solid #e2e7e6;
  }
  .menuLang {
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: 2.3rem;
    width: 35%;
  }
  .menuLang a {
    font-weight: 400;
    font-size: 1.4rem;
    line-height: 1.8rem;
    color: #000000;
  }
  .menu .container {
    display: block;
  }
  .menuMid {
    padding-top: 3.2rem;
    padding-left: 0.7rem;
  }
  .menuMid ul {
    display: flex;
    flex-direction: column;
    row-gap: 1.6rem;
  }
  .menuMid li {
    font-weight: 600;
    font-size: 2.2rem;
    line-height: 2.8rem;
    letter-spacing: -0.03em;
    color: #4d4d4d;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
  }
  .menuMid li a {
    color: #4d4d4d;
  }
  .menuLinks {
    display: flex;
    flex-direction: column;
    row-gap: 1rem;
    padding-top: 2rem;
  }
  .menuLinks a {
    font-size: 1.6rem;
  }
  .menuSubCategory {
    position: fixed;
    top: 10.3rem;
    left: 0;
    background-color: #ffffff;
    z-index: 25;
    width: 100vw;
    padding-left: 7.3rem;
    padding-bottom: 6rem;
    row-gap: 1.8rem !important;
    height: calc(100vh - 18.8rem);
    transition: all 0.4s linear;
    transform: translateX(100%);
    overflow-x: hidden;
    overflow-y: auto;
  }
  .menuMid li:hover + .menuBack {
    transform: translateX(0);
  }
  .menuMid li:hover .menuSubCategory {
    transform: translateX(0);
  }
  body,
  html {
    overflow-x: hidden;
  }
  body.active {
    height: 100vh;
  }
  .menuSubCategory li:first-child a {
    font-weight: 600;
    font-size: 2.5rem;
    line-height: 3.1rem;
    letter-spacing: -0.03em;
    color: #2b2a2a;
    margin-bottom: 1.2rem;
  }
  .menuSubCategory li a {
    font-weight: 500;
    font-size: 1.5rem;
    line-height: 2rem;
    letter-spacing: -0.02em;
    color: #231f20;
  }
  .headerBottomRight {
    display: none;
  }
  #specs > div > div:first-child {
    width: 50%;
  }
  #specs > div > div:last-child {
    width: 50%;
  }
  .rating-share {
    flex-direction: column;
    align-items: flex-start;
    row-gap: 2rem;
  }
  .productShare {
    width: 100%;
  }
  .productShare > div {
    justify-content: space-around;
    width: 100%;
  }
  .productShare > div svg {
    scale: 1.5;
  }
  .productRating::after {
    display: none;
  }
  .categories {
    position: fixed;
    background-color: #ffffff;
    z-index: 10;
    width: 100vw;
    height: 100vh;
    top: 8.7rem;
    left: 0;
    display: block;
    padding: 1rem 2.4rem;
    gap: 0;
    transform: translateX(100%);
    transition: all 0.4s linear;
  }
  .categories.active {
    transform: translateX(0);
  }
  .categoryMenu {
    flex-direction: column;
    align-items: flex-start;
    row-gap: 1.2rem;
    column-gap: 0;
    padding-left: 0.8rem;
    margin-top: 2rem;
  }
  .categoryMenu > li {
    width: 100%;
    border: none;
    pointer-events: none;
  }
  .categoryMenu > li.active .categorySubMenu {
    transform: translate(0);
  }
  .subMenuWrapper {
    display: block;
    position: fixed;
    background-color: #ffffff;
    z-index: 11;
    left: 0;
    top: 8rem;
    width: 100vw;
    margin: 0;
    transform: translateX(100%);
    border: none;
    transition: all 0.4s linear;
    overflow: auto;
  }
  .subMenuWrapper.active {
    transform: translate(0);
  }
  .categorySubMenu ul li a,
  .categorySubMenu ul li:last-child a {
    opacity: 1;
  }
  .headerRight.active2,
  .headerMenu.active2 {
    opacity: 0;
  }
  .categorySubMenu .container > div {
    display: none !important;
  }
  .categorySubMenu > .container {
    flex-direction: column;
  }
  .categorySubMenu > .container > * {
    width: 100%;
  }
  .categorySubMenu > .container > ul li:first-child {
    display: none;
  }
  .categoryMenu > li > a {
    padding: 0;
    font-weight: 600 !important;
    font-size: 1.8rem !important;
    line-height: 2rem !important;
    display: flex;
    align-items: center;
    color: #4d4d4d !important;
    pointer-events: all;
    border: none;
  }
  .categoryMenu > li > a svg {
    display: block;
    margin-left: auto;
    width: 2rem;
    height: 2rem;
  }
  .categoryMenu > li > a > span {
    margin-right: 0.3rem;
    font-weight: 600 !important;
    color: #4d4d4d !important;
  }
  .campaignsLink {
    border: none;
    padding: 0;
  }
  .bannerSwiper .swiper-slide a {
    height: 10.3rem;
  }

  .detailSearchContent .tabs-container .imgContainer {
    display: none;
  }
  .contactPage h1 {
    font-size: 3.2rem;
    line-height: 3.8rem;
    margin-bottom: 3.2rem;
  }
  .contact {
    flex-wrap: wrap;
    row-gap: 1.4rem;
  }
  .contact .phoneNumber {
    font-size: 2.2rem;
    justify-content: flex-start;
  }
  .contact .phoneNumber svg {
    scale: 0.6;
  }
  .contact > div:first-child {
    width: 40%;
  }
  .contact .callOrder {
    font-size: 1.4rem;
    line-height: 1.4rem;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1rem 2rem;
    width: fit-content;
  }
  .contact > div:nth-child(2) {
    width: 20%;
    display: flex;
    justify-content: center;
  }
  .contact > div:nth-child(3) {
    width: 40%;
    display: flex;
    justify-content: flex-end;
  }
  .contact .headerAddress {
    flex-direction: column;
    width: 100%;
    row-gap: 1rem;
  }
  .contact .headerAddress span {
    font-size: 1.6rem;
    width: 50%;
  }
  .contact .headerAddress span:last-child {
    justify-content: center;
  }
  .contact .headerSocials {
    width: 100%;
    justify-content: center !important;
  }
  .contactMap {
    border-radius: 0.8rem;
    margin-bottom: 4rem;
  }
  .contactUs {
    row-gap: 2rem;
  }
  .contactUs > div {
    width: 100%;
  }
  .contactUsLeft {
    padding: 1rem;
    border-radius: 0.8rem;
  }
  .contactUsLeft .imgContainer {
    height: 35rem;
    border-radius: 0.6rem;
  }
  .contactUsRight {
    padding: 2rem;
  }
  header > div:nth-child(2) {
    padding: 3rem 0 1.6rem;
  }
  header .logo img {
    width: 80%;
  }
  .headerButtons {
    display: flex;
    align-items: center;
    column-gap: 2.1rem;
  }
  .headerButtons button {
    background-color: transparent;
    padding: 0;
    border: none;
  }
  .menuBtn button {
    display: flex;
    flex-direction: column;
    row-gap: 0.6rem;
  }
  .menuBtn button span {
    display: inline-block;
    width: 2.452rem;
    height: 2px;
    background-color: #000000;
    border-radius: 10rem;
    transition: all 0.3s;
    position: relative;
  }
  .menuBtn button.active span:nth-child(2) {
    opacity: 0;
  }
  .menuBtn button.active span:nth-child(1) {
    transform: translate(0, 8px) rotate(45deg);
  }
  .menuBtn button.active span:nth-child(3) {
    transform: translate(0, -8px) rotate(-45deg);
  }
  .searchBtn,
  .menuBtn {
    display: block;
  }
  .mobileMenuTop {
    display: block;
  }
  .mobileMenuTop > div {
    border: 1px solid #e2e7e6;
    border-radius: 9.3px;
    display: flex;
  }
  .mobileMenuTop > div > a {
    width: 22%;
    height: 4.8rem;
    display: flex;
    justify-content: center;
    align-items: center;
    border-right: 1px solid #e2e6e6;
  }
  .mobileLang {
    width: 34%;
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: 2.3rem;
  }
  .mobileLang a {
    font-style: normal;
    font-weight: 400;
    font-size: 1.4rem;
    line-height: 1.8rem;
    color: #000000;
  }
  .headerMenu {
    position: fixed;
    z-index: 11;
    left: 0;
    top: 32.7rem;
    padding-left: 3.2rem;
    flex-direction: column;
    align-items: flex-start;
    column-gap: 0;
    row-gap: 1.2rem;
    transform: translateX(100%);
    transition: all 0.4s linear;
    width: 100vw;
  }
  .headerMenu.active {
    transform: translateX(0);
  }
  .headerMenu li a {
    font-weight: 600;
    font-size: 1.8rem;
    line-height: 2rem;
  }
  header > div:first-child {
    border-bottom: none;
  }
  .headerRight {
    display: none;
  }
  .menu .headerRight {
    display: flex;
    flex-wrap: wrap;
    padding-top: 10rem;
  }
  .headerRight.active {
    transform: translateX(0);
  }
  .headerRight > div:first-child {
    width: 33%;
    border-left: none;
    height: auto;
    justify-content: flex-start;
  }
  .headerRight > div:nth-child(2) {
    width: 20%;
    height: auto;
  }

  .headerRight > div:nth-child(3) {
    width: 47%;
    height: auto;
    border-right: none;
    justify-content: flex-end;
  }
  .lang {
    display: none !important;
  }
  header .headerAddress,
  header .headerSocials {
    width: 50%;
    border-top: 1px solid #f2f0f0;
    height: 5rem;
    padding-top: 1.2rem !important;
    margin-top: 1rem;
    border-right: none;
  }
  header .headerSocials {
    column-gap: 2.5rem;
  }
  .headerRight > div {
    padding: 0;
  }
  .container {
    padding-left: 2rem;
    padding-right: 2rem;
    margin: 0;
  }
  .card > div:nth-child(2) {
    width: 20%;
  }
  .card select {
    width: 6rem;
    padding-left: 1rem;
  }
  .card > div {
    width: 20% !important;
  }
  .brandImg img {
    width: 90%;
    max-height: 90%;
    object-fit: contain;
  }
  .detailSearchContent .react-tabs__tab-list {
    overflow-x: auto;
    overflow-y: hidden;
  }
  .bannerSwiper .swiper-button-next,
  .bannerSwiper .swiper-button-prev {
    display: none;
  }
  .bannerSwiper .swiper-pagination {
    display: none !important;
  }
  .detailSearch {
    padding: 3.7rem 0 6.4rem;
  }
  .detailSearch h2 {
    font-size: 2.4rem;
    line-height: 3rem;
  }
  .searchTabs {
    overflow-x: auto;
    overflow-y: hidden;
    column-gap: 3.4rem;
    padding-bottom: 2.1rem;
  }
  .searchTabs::-webkit-scrollbar {
    display: none;
  }
  .productDetailLeft .imgContainers img {
    max-width: 100%;
    object-fit: contain;
  }
  .detailSearchContent {
    padding: 2.2rem 2rem 10.3rem;
  }
  .detailSearchContent .react-tabs__tab-list {
    border-bottom: 1px solid #dee0e0;
    display: flex;
    column-gap: 3.4rem;
    padding-bottom: 0.8rem;
    margin-bottom: 0;
  }
  .detailSearchContent .react-tabs__tab {
    font-style: normal;
    font-weight: 400;
    font-size: 1.87rem;
    line-height: 2.3rem;
    white-space: nowrap;
    letter-spacing: -0.02em;
    color: #91a8a7;
    position: relative;
    border-top: none;
    border-left: none;
    border-right: none;
    border-radius: 0;
    padding: 0;
  }
  .detailSearchContent .react-tabs__tab:focus::after {
    content: "";
    position: absolute;
    width: 100%;
    height: 2px;
    background-color: #ff2f49;
    bottom: -0.8rem;
    left: 0;
  }

  .detailSearchContent .react-tabs__tab--selected::after {
    content: "";
    position: absolute;
    width: 100%;
    height: 2px;
    background-color: #ff2f49;
    bottom: -0.8rem;
    left: 0;
  }

  .detailSearchContent .react-tabs__tab--selected {
    color: #202e2d;
    font-weight: 700;
  }
  .detailSearchContent .tabs-container {
    padding-top: 3.2rem;
  }
  .detailSearchContent .tabs-container img {
    width: 60%;
    right: 0;
    z-index: -1;
  }
  .detailSearchContent .tabs-container button {
    height: 4.9rem;
    bottom: -1rem;
  }
  .detailSearchContent .checkboxGroup {
    column-gap: 1.4rem;
    padding: 0 0 2.3rem;
  }
  .detailSearchContent .checkboxGroup label {
    font-size: 1.6rem;
    line-height: 1.5rem;
    width: max-content;
  }
  .detailSearchContent .checkboxGroup div {
    column-gap: 0.4rem;
  }
  .detailSearchContent .inputGroup {
    flex-direction: column;
    column-gap: 0;
    row-gap: 1rem;
    margin-bottom: 0;
    width: 100% !important;
  }
  .detailSearchContent .inputGroup .form-group {
    width: 100%;
  }
  .css-13cymwt-control,
  .css-t3ipsp-control {
    width: 100% !important;
  }
  .detailSearchContent .select {
    width: 100%;
  }
  .footerLogo svg {
    width: 9.5rem;
  }
  .productCategories {
    padding: 4rem 0 5rem;
  }
  .productCategories h1,
  .products h1,
  .services h1 {
    font-size: 2.4rem;
    line-height: 3rem;
  }
  .services h1 {
    margin-bottom: 3.2rem;
  }
  .productCategoriesContent {
    flex-direction: column;
    row-gap: 7.5rem;
  }
  .productCategoriesContent a {
    width: 100%;
  }
  .productCategoriesContent a span {
    padding: 0 10rem 1.6rem 3.2rem;
    height: 12.6rem;
    font-size: 1.9rem;
    line-height: 2rem;
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;
  }
  .productCategoriesContent a img {
    height: 7rem;
    top: -4.5rem;
  }
  .products .seeAll span:first-child {
    display: none;
  }
  .products {
    padding: 4.4rem 0;
  }
  .productsContent {
    padding: 0;
    border-top: 0;
  }
  .brand {
    row-gap: 2rem;
  }
  .brandSwiper {
    padding: 0;
  }
  .productsTop {
    margin-bottom: 2.4rem;
  }
  .services {
    padding: 4.1rem 0 5.5rem;
  }
  .servicesContent {
    flex-direction: column;
    row-gap: 2.2rem;
  }
  .service {
    width: 100%;
    padding: 0;
    border: 0;
    height: auto;
    row-gap: 1rem;
  }
  .serviceName span {
    width: 100%;
  }
  .serviceName {
    column-gap: 2.3rem;
  }
  .videoContainer {
    height: 20rem;
    margin-bottom: 1.7rem;
  }
  .videoContainer img {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
  .video p,
  .blog p {
    font-size: 1.7rem;
    line-height: 2.1rem;
    width: 90%;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
  .blogImg {
    height: 70vw;
    margin-bottom: 1.7rem;
  }
  .blogsSwiper .blogImg img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .blogDetailsPage .blogImg img {
    height: auto !important;
  }
  .blogDetailsPage .blogImg img {
    width: 100%;
    height: auto;
  }

  .blog p {
    padding-left: 0.5rem;
  }
  .bottomText h3 {
    width: 100%;
  }
  .aboutPage h2 {
    font-size: 2.8rem;
    line-height: 100%;
  }
  footer {
    padding: 4rem 0 9rem;
    display: flex;
    flex-direction: column;
  }
  footer > .container {
    display: flex;
    flex-direction: column-reverse;
  }
  footer > :nth-child(1) {
    order: 2;
  }
  footer > :nth-child(2) {
    order: 1;
  }
  footer > :nth-child(3) {
    order: 3;
  }
  .footerContent {
    flex-direction: column;
    padding-top: 0;
    padding-bottom: 4.3rem;
    row-gap: 4.1rem;
    padding-left: 0;
    width: 100%;
  }
  .footerContent > div {
    width: 100%;
  }
  .footerMid {
    padding: 0;
  }
  .footerRight {
    justify-content: flex-start;
  }
  .footerLeft {
    flex-direction: column;
    row-gap: 4rem;
  }

  .footerLeft ul {
    width: 100%;
  }
  .footerLeft > ul > a {
    margin-bottom: 0.8rem;
  }
  .footerMenu {
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    padding-right: 4rem;
  }
  .footerContact {
    flex-wrap: wrap;
    padding-top: 1.9rem;
    padding-bottom: 3.5rem;
  }
  .footerLogo,
  .footerPhone,
  .footerContact > div:nth-child(3) {
    width: calc(100% / 3) !important;
    border: none;
  }
  footer .headerAddress {
    width: 100%;
    border: none;
    padding: 2.3rem 3rem 2.7rem 0.5rem !important;
    flex-direction: row;
    justify-content: space-between;
    gap: 0;
  }
  footer .headerAddress span:last-child {
    justify-content: flex-end;
  }
  .footerLogo img {
    width: 80%;
  }
  .footerPhone a {
    column-gap: 0.7rem;
  }
  .footerPhone a svg {
    width: 1.8rem;
    height: 1.8rem;
  }
  .footerPhone a span {
    font-size: 2rem;
  }
  .footerContact .callOrder {
    font-size: 1.1rem;
    line-height: 1.2rem;
    width: max-content;
    padding: 0;
    height: 3.5rem;
    width: 85%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .footerSocials {
    width: 100% !important;
  }
  .copyright > .container {
    flex-direction: column;
  }
  .copyright p {
    text-align: center;
    margin-bottom: 3.2rem;
  }
  .breadcrumb {
    transform: translateY(0);
  }
  .breadcrumb ul li a {
    font-size: 1rem;
  }
  .aboutUs {
    flex-direction: column;
    padding: 4rem 0 6rem;
  }
  .aboutUs > div {
    width: 100% !important;
  }
  .aboutPage p {
    width: 100%;
    font-size: 1.4rem;
  }
  .aboutPage h1 {
    font-size: 3.2rem;
    line-height: 4rem;
  }
  .aboutVideo {
    margin-top: 2rem;
    height: 20rem;
    display: flex;
  }
  .aboutVideo img {
    width: 100%;
    height: auto;
  }
  .aboutVideo a span,
  .blogDetailVideo a span {
    height: 4rem;
    font-size: 1.1rem;
    padding: 0 3rem;
  }
  .aboutVideo a span svg,
  .blogDetailVideo a span svg {
    width: 2rem;
    height: 2rem;
  }
  .statistics {
    padding: 0;
    flex-wrap: wrap;
  }
  .statistics > div {
    width: 50%;
    padding: 1rem 2rem;
    justify-content: center;
    align-items: center;
  }
  .statistics > div:first-child {
    border-bottom: 1px solid #ece9e9;
  }
  .statistics > div:nth-child(2) {
    border-bottom: 1px solid #ece9e9;
    border-right: none;
  }
  .statistics > div > span:last-child {
    width: 100%;
    text-align: center;
    font-size: 1.2rem;
  }
  .statistics > div > span:first-child {
    font-size: 4rem;
  }
  .missionVision {
    padding: 4rem 0;
    flex-direction: column-reverse;
  }
  .missionVision > div {
    width: 100% !important;
  }
  .missionVision > div:last-child {
    padding: 0;
  }
  .missionVision .imgContainer {
    height: 20rem;
    margin-top: 2rem;
  }
  .missionVision .imgContainer img {
    width: 100%;
    height: auto;
  }
  .breadcrumb {
    display: none;
  }
  .certificates {
    padding: 2rem 0 4rem;
  }
  .certificate:first-child,
  .certificate:nth-child(3n + 1) {
    padding: 0;
  }
  .certificate {
    width: calc(50% - 0.8rem);
    padding: 0;
  }
  .certificateImg {
    height: 25rem;
    display: block;
  }
  .certificateImg img {
    width: 95%;
    max-height: 90%;
    object-fit: contain;
  }
  .certificates > div {
    justify-content: space-between;
    row-gap: 1.6rem;
  }
  .certificates h1 {
    margin-bottom: 2rem;
  }
  .ourTeam {
    padding: 2rem 0 8rem;
    flex-direction: column;
  }
  .ourTeam > div {
    width: 100% !important;
  }
  .ourTeam .imgContainer {
    height: 20rem;
    margin-top: 2rem;
  }
  .basketPage {
    padding: 4rem 0 6rem;
  }
  .basketTop > .container {
    padding-right: 2rem;
  }
  .basketPage h1 {
    font-size: 2.4rem;
  }
  .seeAll {
    column-gap: 0.5rem;
  }
  .seeAll span {
    font-size: 1.2rem;
  }
  .seeAll svg {
    width: 2rem;
  }
  .basketContent > .container > .form {
    flex-direction: column-reverse;
  }
  .basketContent > .container > .form > div {
    width: 100%;
    padding: 2rem 0;
  }
  .addNewAddr {
    width: 95% !important;
    padding: 4rem 3rem !important;
    z-index: 10;
  }
  .addNewAddr h4 {
    font-size: 2rem;
  }
  .modalClose {
    top: 2rem;
    right: 2rem;
  }
  .modalClose svg {
    width: 1.8rem;
    height: 1.8rem;
  }
  .basketItems .order {
    width: 95%;
    padding: 1rem;
  }
  .basketItems .orderImg {
    width: 50%;
    height: auto;
  }
  .basketItems .orderBody {
    width: 50%;
    row-gap: 1rem;
  }
  .basketItems .orderBody > div:last-child {
    flex-direction: column;
    row-gap: 4rem;
  }
  .basketItems .orderName {
    width: 100%;
    font-size: 1rem;
  }
  .orderPrice {
    font-size: 2rem;
  }
  .total {
    flex-direction: column-reverse;
    row-gap: 2rem;
    padding: 0;
    align-items: flex-start;
  }
  .totalPrice {
    margin-bottom: 0.8rem;
  }
  .total button {
    height: 4.6rem;
    font-size: 1.2rem;
  }
  .addresses .radioGroup label {
    padding: 3rem 1rem 4rem;
  }
  .check {
    width: 2rem;
    height: 2rem;
  }
  .blogs {
    row-gap: 3rem;
    justify-content: space-between;
  }
  .blogs > div {
    width: 48.5%;
    padding: 0 !important;
  }
  .blogs .blogImg {
    height: 40vw;
    margin-bottom: 1rem;
  }
  .blogs .blogImg img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .video p,
  .blog p {
    font-size: 1.3rem;
    line-height: 1.6rem;
  }
  .changePassword {
    padding-left: 0;
  }
  .changePassword form {
    width: 100%;
  }
  .blogsPage h1,
  .blogDetailsPage h1 {
    font-size: 2.8rem;
    line-height: 3rem;
    margin-bottom: 2rem;
  }
  .blogsPage {
    padding-bottom: 6rem;
  }
  .blogTopImg {
    height: auto;
  }
  .blogTopImg img {
    width: 100%;
  }
  .blogText {
    padding: 3rem 0;
  }
  .blogText h2,
  .blogText h3 {
    font-size: 2rem;
    margin-bottom: 1rem;
  }
  .blogText p,
  .blogText ul li {
    width: 100%;
    font-size: 1.4rem;
  }
  .blogDetailSwiper .swiper-slide {
    height: 20rem;
  }
  .blogDetailSwiper .swiper-slide img {
    object-fit: cover;
  }
  .blogDetailSwiper .swiper-button-next,
  .blogDetailSwiper .swiper-button-prev {
    width: 4rem;
    height: 4rem;
    border-radius: 15px;
  }
  .blogDetailSwiper .swiper-button-next svg,
  .blogDetailSwiper .swiper-button-prev svg {
    width: 2.2rem;
    height: 2.2rem;
  }
  .blogDetailSwiper .swiper-navigation {
    bottom: -2rem;
  }
  .blogDetailVideo {
    height: 20rem;
  }
  .blogShare {
    justify-content: flex-start;
  }
  .blogShare::after {
    display: none;
  }
  .blogShare > div {
    justify-content: space-between;
    column-gap: 0;
    padding: 0;
    width: 100%;
  }
  .blogShare > div > span {
    font-size: 1.2rem;
    width: max-content;
  }
  .blogShare button span {
    font-size: 1rem;
  }
  .blogShare > div > div a,
  .blogShare > div > div button {
    width: 8rem;
    height: 3.8rem;
  }
  .brandsPage {
    padding: 4rem 0 7rem;
  }
  .brandsPage h1 {
    font-size: 2.8rem;
    line-height: 3rem;
  }
  .brandsPage .brand {
    width: 50%;
  }
  .campaignsPage {
    padding: 4rem 0 7rem;
  }
  .campaignsPage h1 {
    font-size: 2.8rem;
    line-height: 3rem;
  }
  .campaignsPage p {
    font-size: 1.2rem;
  }
  .campaigns {
    padding-top: 2rem;
    padding-bottom: 0;
  }
  .campaigns .container {
    justify-content: space-between;
    row-gap: 3rem;
  }
  .campaigns .container > div {
    width: 48.5%;
    padding: 0 !important;
  }
  .campaignImg {
    height: 40vw;
    margin-bottom: 1rem;
  }
  .campaignImg img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .campaign h4 {
    font-size: 1.3rem;
    line-height: 1.6rem;
    width: 90%;
  }
  .profilePage h2 {
    font-size: 2.8rem;
  }
  .profilePage {
    padding-top: 4rem;
  }
  .profileContent > .container {
    flex-direction: column;
  }
  .profileContent > .container > div {
    width: 100%;
  }
  .profileLeft {
    padding-top: 2rem;
    padding-bottom: 3rem;
    border-bottom: 1px solid #f2f0f0;
  }
  .profileRight {
    padding-top: 3rem;
  }
  .profileDetails {
    flex-direction: column-reverse;
  }
  .profileDetails > div:not(.addNewAddr) {
    width: 100% !important;
    padding: 0 0 3rem !important;
  }
  .profileRight h5 {
    font-size: 1.8rem;
  }
  .detailSearchContent .checkboxGroup {
    flex-direction: column;
    align-items: flex-start;
    row-gap: 1.4rem;
  }
  .detailSearchContent .checkboxGroup > div span {
    width: 3.6rem;
    height: 3.6rem;
  }
  .addNewAddress,
  .addProduct {
    height: 3.6rem;
    font-size: 1.2rem;
  }
  .addNewAddress svg,
  .addProduct svg {
    height: 1.6rem;
    width: 1.6rem;
  }
  .addresses .radioGroup {
    width: 48%;
  }
  .addresses .radioGroup p {
    font-size: 1.2rem;
  }
  .favoritesPage {
    padding: 0;
  }
  .favoritesPage h5 {
    padding: 0;
  }
  .favorites {
    row-gap: 5rem;
  }
  .favorite {
    width: 100%;
    padding: 0;
  }
  .ordersContent {
    padding-top: 2rem;
    padding-bottom: 4rem;
  }

  .ordersContent > div:first-child {
    display: none;
  }

  .ordersContent > div:first-child span {
    font-size: 0.8rem;
  }
  .ordersContent > .orders:nth-child(2) {
    border-top-left-radius: 0.8rem;
    border-top-right-radius: 0.8rem;
  }
  .orders > div:first-child,
  .orderDetails {
    padding: 1rem 1.6rem 2.5rem 1rem;
  }

  .orders > div:first-child > div:first-child span:first-child {
    display: none;
  }
  .orders > div:first-child > div:first-child span:last-child {
    font-style: normal;
    font-weight: 400;
    font-size: 2rem;
    line-height: 2.4rem;
    letter-spacing: -0.02em;
    font-feature-settings: "pnum" on, "lnum" on, "kern" off;
    color: #424242;
    display: inline-block;
  }
  .toggle {
    padding: 2.4rem 2.8rem 2.5rem !important;
  }
  .orders span {
    font-size: 0.8rem;
  }

  /* .orders>div>div:last-child button span:first-child,
  .toggle.active .seeDetails::before {
    display: none;
  } */
  .orders > div > div:last-child button span:last-child,
  .orders > div > div:last-child button span:nth-child(2) {
    display: none !important;
  }
  .orders > div > div:last-child {
    text-align: right;
  }

  .orders > div > div:last-child button {
    display: inline-flex;
  }
  .toggle > div:nth-child(3),
  .toggle > div:nth-child(4),
  .toggle > div:nth-child(5),
  .toggle > div:nth-child(6) {
    display: none;
  }
  .toggle > div:first-child,
  .toggle > div:last-child {
    width: 20% !important;
  }
  .toggle > div:last-child {
    text-align: right;
  }
  .toggle > div:nth-child(2) {
    width: 60%;
    text-align: center;
  }
  .toggle > div:nth-child(2) span {
    font-size: 1.6rem;
  }
  .plus::after,
  .plus::before {
    height: 1.2rem;
  }
  .orderDetails > div:last-child {
    row-gap: 1rem;
    padding-right: 1.4rem;
  }
  .orderDetails > div:first-child {
    display: flex;
    padding: 0 1rem 2.6rem;
    width: 100%;
  }
  .orderDetails > div:first-child > div:first-child {
    width: 40%;
    display: flex;
    flex-direction: column;
    gap: 1.8rem;
  }
  .orderDetails > div:first-child > div:last-child {
    width: 60%;
    display: flex;
    flex-direction: column;
    gap: 1.9rem;
  }
  .orderDetails > div:first-child > div:first-child span {
    font-style: normal;
    font-weight: 400;
    font-size: 1.4rem;
    line-height: 116.1%;
    letter-spacing: -0.02em;
    font-feature-settings: "liga" off;
    color: #424242;
  }
  .orderDetails > div:first-child > div:last-child span {
    font-style: normal;
    font-weight: 400;
    font-size: 1.1rem;
    line-height: 116.1%;
    letter-spacing: -0.02em;
    font-feature-settings: "liga" off;
    color: #555555;
  }
  .orderDetails > div:first-child > div:last-child span:first-child {
    width: 8.2rem;
    height: 2.6rem;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    background-color: transparent;
    border-radius: 0.67rem;
    border: 0.01rem solid #174a3b;
    color: #174a3b;
    font-size: 1.2rem;
  }
  .orderDetails > div:first-child > div:last-child span:first-child.paid {
    background-color: #174a3b;
  }

  .order {
    width: 100%;
    padding: 0 0.5rem;
  }

  .orderImg {
    padding: 0.9rem 1.3rem 0.7rem;
    height: 50%;
    overflow: hidden;
  }

  .orderBody {
    padding: 1rem 1rem 0;
  }

  .orderBody > div:first-child > div {
    gap: 0.3rem;
  }

  .orderBody > div:first-child > div > span:last-child {
    font-size: 1.8rem;
  }

  .orderBody > div:last-child {
    gap: 0.5rem;
  }

  .orderBody > div:last-child > span:last-child {
    display: inline-block;
    text-align: left;
  }
  .profileDetails > div:first-child::after {
    display: none;
  }
  .supportPage {
    padding: 4rem 0 7rem;
  }
  .supportPage h1 {
    font-size: 2.8rem;
    line-height: 3rem;
  }
  .supportHeader {
    padding-left: 2rem;
    height: 8rem;
  }
  .supportName {
    column-gap: 1.6rem;
    width: 100%;
  }
  .supportName svg {
    width: 2.6rem;
    height: 2.6rem;
  }
  .supportName span {
    font-size: 1.6rem;
    line-height: 1.8rem;
    display: flex;
    align-items: center;
  }
  .supportName span:last-child {
    width: 100%;
  }
  .supportHeader > div:last-child {
    width: 25%;
  }
  .supportHeader .plus {
    width: 1.3rem;
    height: 0.2rem;
  }
  .supportBody {
    padding: 2rem;
  }
  .supportBody h3 {
    font-size: 1.8rem;
    margin-bottom: 1.4rem;
  }
  .supportBody > div {
    padding-right: 2.4rem;
    height: 40rem;
  }
  .supportBody > div::-webkit-scrollbar {
    width: 2px;
    border-radius: 10rem;
  }
  .videosPage .videoContainer {
    height: 25vw;
  }
  .videosPage .videoContainer button {
    width: 4rem;
    height: 4rem;
  }
  .videosPage .videoContainer button svg {
    width: 1.2rem;
    height: 1.5rem;
  }
  .videosPage .videoContainer {
    margin-bottom: 0.7rem;
  }
  .allProducts {
    padding: 4rem 0 6rem;
  }
  .allProducts h1 {
    font-size: 2.4rem;
    line-height: 3rem;
    margin-bottom: 0.7rem;
  }
  .allProducts > .container > div {
    flex-wrap: wrap;
    row-gap: 3rem;
    justify-content: space-between;
    align-items: center;
  }
  .allProducts p {
    font-size: 1.2rem;
    width: 100%;
  }
  .filter {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    background-color: #ffffff;
    width: 100vw;
    height: 100vh;
    overflow: auto;
    z-index: 15;
  }
  .filter.active {
    display: block;
  }
  .productCards {
    width: 100%;
    padding-left: 0;
  }
  .productCards .product {
    width: 100% !important;
  }
  .filterBtn {
    font-style: normal;
    font-weight: 400;
    font-size: 1.4rem;
    line-height: 1.8rem;
    color: #000000;
    background-color: transparent;
    border: 1px solid #e2e7e6;
    border-radius: 9.3px;
    height: 4rem;
    padding: 0 3rem;
    display: block !important;
  }
  .filterTop {
    padding: 6rem 2rem 3rem;
    position: relative;
  }
  .filter > div:not(:first-child) {
    padding: 0 2rem;
  }
  .filterTop h5 {
    font-size: 2.8rem;
  }
  .closeFilter {
    position: absolute;
    top: 1.5rem;
    right: 1.5rem;
    display: block !important;
  }
  .search {
    position: fixed;
    width: 100vw;
    background-color: #fff;
    left: 0;
    top: 0;
    z-index: 10;
    padding: 2rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    display: none;
  }
  .search.active {
    display: flex !important;
  }
  .search form {
    width: 80%;
  }
  .search .inputGroup {
    width: 100%;
  }
  .search input {
    width: 80%;
  }
  .search input.active {
    width: 80%;
  }
  .search input:focus {
    width: 80%;
  }
  .searchBtn {
    transition: all 0.3s;
  }
  .searchBtn.active {
    visibility: hidden;
    opacity: 0;
  }
  .backBtn {
    background-color: transparent !important;
    width: auto !important;
    display: block !important;
  }
  .searchResults {
    top: 9.4rem;
    padding: 0 2rem;
    height: 90vh;
  }
  .searchResultName {
    font-size: 1.2rem;
    line-height: 1.6rem;
    padding-left: 2rem;
    width: 50%;
  }
  .searchPrice {
    flex-direction: column-reverse;
  }
  .searchPrice span:first-child {
    font-size: 1.7rem;
  }
  .searchPrice span:last-child {
    width: max-content;
    font-size: 2rem;
  }

  .searchResult .imgContainer {
    width: 9rem;
    height: 9rem;
  }
  .productDetailsPage {
    padding-top: 4rem;
  }
  .productDetailsPage h1 {
    font-size: 2.4rem;
    line-height: 2.6rem;
  }
  .productDetailContent {
    flex-direction: column;
  }
  .productDetailLeft,
  .productDetailRight {
    width: 100%;
  }
  .productDetailLeft .imgContainers {
    height: 40rem;
  }
  .productDetailRight {
    padding-left: 0;
  }
  .prodRightTop {
    flex-direction: column;
    row-gap: 6rem;
  }
  .prodRightTop > div {
    width: 100% !important;
    padding-left: 0 !important;
  }
  .credit {
    padding: 1rem;
  }
  .creditContent {
    padding: 0;
  }
  .creditHeader span:first-child,
  .creditBody label > :first-child,
  .creditHeader span:nth-child(2),
  .creditBody label > :nth-child(2) {
    width: 20%;
  }
  .card span {
    font-size: 1.3rem;
  }
  .cardImg {
    display: none;
  }
  .creditHeader span {
    font-size: 1rem;
    white-space: nowrap;
  }
  .creditBody .radioGroup label > span {
    font-size: 1rem;
  }
  .credit .buttons {
    flex-wrap: wrap;
    justify-content: space-between;
    row-gap: 1rem;
  }
  .credit .buttons a,
  .credit .buttons button {
    width: 48%;
    margin-right: 0;
    font-size: 1rem;
    padding: 0 1rem;
  }
  .credit h3 {
    width: 100%;
  }
  #prodInfoTabs li a {
    width: max-content;
    padding: 0 2rem;
  }
  #prodInfoTabs {
    overflow: auto;
  }
  #prodInfoTabs::-webkit-scrollbar {
    display: none;
  }
  #prodInfoTabs li {
    width: auto;
  }
  .prodInfoTabs {
    padding: 3rem 2rem 4rem;
  }
  #specs {
    display: flex !important;
    flex-direction: column;
    row-gap: 1rem;
  }
  #specs > div > div {
    width: 50%;
  }
  #sizes {
    display: flex !important;
    flex-direction: column;
    row-gap: 2.4rem !important;
  }
  #info p {
    font-size: 1.3rem;
  }
  #prodInfoTabs li a {
    font-size: 1.6rem;
  }
  .subMenuClose {
    display: block;
    margin-bottom: 2.3rem;
    display: flex;
    align-items: center;
    column-gap: 1.9rem;
    font-style: normal;
    font-weight: 600;
    font-size: 2.5rem;
    line-height: 3.1rem;
    letter-spacing: -0.03em;
    color: #2b2a2a;
  }
  .categorySubMenu > .container > ul {
    padding-left: 5rem;
  }
  .blogHover span {
    font-size: 1.6rem;
  }
  .blogHover {
    row-gap: 1rem;
  }
  .blogHover svg {
    width: 4rem;
  }
  .basketRegister .addNewAddr {
    padding: 0 !important;
  }
}
/*new future and its so important*/
.selected-lang {
  color: #0d8359 !important;
  display: none;
}
@keyframes loader {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.loader {
  animation: loader infinite 1s;
  width: 100px;
  opacity: 0.5;
}
/*otp kod button*/
.otp-button {
  margin-top: 20px;
  width: 100%;
  height: 5.2rem;
  background: #0d8359;
  border-radius: 8px;
  font-style: normal;
  font-weight: 700;
  font-size: 1.6rem;
  line-height: 2rem;
  text-align: center;
  letter-spacing: 0.04em;
  text-transform: uppercase;
  color: #ffffff;
  border: none;
  transition: all 0.3s;
}

.video-modal {
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 9999999;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.6);
  transition: all 1s;
}
.video-modal-container {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.video-modal iframe {
  width: 80%;
  height: 80%;
  margin: auto;
}
.video-modal button.close {
  background: transparent;
  position: absolute;
  right: 15px;
  font-size: 50px;
  border: none;
  color: white;
}
